import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import TablePagination from '../../components/TablePagination';

import LoginType from '../../constants/LoginType';
import UserType from '../../constants/UserType';
import UserRole from '../../constants/UserRole';

import * as utils from '../../utils';
import * as api from '../../apis';

const UserList = () => {
	const navigate = useNavigate();
	const [loginTypeFilter, setLoginTypeFilter] = useState([
		{ id: 'login_type-1', name: LoginType.toString(LoginType.COMMON), selected: false, value: LoginType.COMMON },
		{ id: 'login_type-2', name: LoginType.toString(LoginType.SSO), selected: false, value: LoginType.SSO },
	]);
	const [userTypeFilter, setUserTypeFilter] = useState([
		{ id: 'user_type-0', name: UserType.toString(UserType.COMMON), selected: false, value: UserType.COMMON },
		{ id: 'user_type-1', name: UserType.toString(UserType.LEARNER), selected: false, value: UserType.LEARNER },
		{ id: 'user_type-4', name: UserType.toString(UserType.STAFF), selected: false, value: UserType.STAFF },
		{ id: 'user_type-5', name: UserType.toString(UserType.ASSISTANT), selected: false, value: UserType.ASSISTANT },
		{
			id: 'user_type-6',
			name: UserType.toString(UserType.INSTRUCTOR),
			selected: false,
			value: UserType.INSTRUCTOR,
		},
		{
			id: 'user_type-9',
			name: UserType.toString(UserType.ADMINISTRATOR),
			selected: false,
			value: UserType.ADMINISTRATOR,
		},
	]);
	const [userRoleFilter, setUserRoleFilter] = useState([
		{ id: 'user_role-0', name: UserRole.toString(UserRole.COMMON), selected: false, value: UserRole.COMMON },
		{
			id: 'user_role-1',
			name: UserRole.toString(UserRole.ROOM_ADMINISTRATOR),
			selected: false,
			value: UserRole.ROOM_ADMINISTRATOR,
		},
		{
			id: 'user_role-2',
			name: UserRole.toString(UserRole.PRODUCT_ADMINISTRATOR),
			selected: false,
			value: UserRole.PRODUCT_ADMINISTRATOR,
			use_product: true,
		},
		{
			id: 'user_role-5',
			name: UserRole.toString(UserRole.CONSULT_ADMINISTRATOR),
			selected: false,
			value: UserRole.CONSULT_ADMINISTRATOR,
		},
		{
			id: 'user_role-4',
			name: UserRole.toString(UserRole.UNIT_ADMINISTRATOR),
			selected: false,
			value: UserRole.UNIT_ADMINISTRATOR,
		},
		{
			id: 'user_role-9',
			name: UserRole.toString(UserRole.SYSTEM_ADMINISTRATOR),
			selected: false,
			value: UserRole.SYSTEM_ADMINISTRATOR,
		},
	]);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [pageIndex, setPageIndex] = useState(1);

	const [userList, setUserList] = useState(null);
	const [initialized, setInitialized] = useState(false);

	const tableTopRef = useRef(null);

	useEffect(() => {
		getUsersWithFilter();
	}, [pageIndex]);

	const getUsersWithFilter = () => {
		let filterParams = [`page=${pageIndex}`];
		if (searchKeyword) filterParams.push(`keyword=${searchKeyword}`);
		userTypeFilter.forEach((filter) => {
			if (filter.selected) {
				filterParams.push(`user_types[]=${filter.value}`);
			}
		});
		loginTypeFilter.forEach((filter) => {
			if (filter.selected) {
				filterParams.push(`login_types[]=${filter.value}`);
			}
		});
		userRoleFilter.forEach((filter) => {
			if (filter.selected) {
				filterParams.push(`user_roles[]=${filter.value}`);
			}
		});
		getUsersFromApi(filterParams);
	};

	const getUsersFromApi = (filterParams) => {
		api.getUsers(filterParams)
			.then((res) => {
				if (res && res.data) {
					if (!initialized) setInitialized(true);
					setUserList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onClickSearch = () => {
		getUsersWithFilter();
	};

	const onClickDetail = (id) => {
		navigate(`/admin/users/${id}/edit`);
	};

	const onPageChange = (page) => {
		setPageIndex(page);
		tableTopRef.current.scrollIntoView();
	};

	const renderFilterList = () => {
		return (
			<>
				<FilterRow>
					<FilterTitle>로그인 유형</FilterTitle>
					<FilterContents>
						{loginTypeFilter.map((loginType, idx) => {
							return (
								<FilterCheckboxGroup key={idx}>
									<FilterCheckbox
										type="checkBox"
										name={loginType.id}
										id={loginType.id}
										checked={loginType.selected}
										onChange={(e) => {
											let changedFilter = loginTypeFilter.map((filter) => {
												if (filter.id === loginType.id) {
													return {
														...filter,
														selected: e.currentTarget.checked,
													};
												}
												return filter;
											});
											setLoginTypeFilter(changedFilter);
										}}
									/>
									<label htmlFor={loginType.id}>{loginType.name}</label>
								</FilterCheckboxGroup>
							);
						})}
					</FilterContents>
				</FilterRow>
				<FilterRow>
					<FilterTitle>사용자 유형</FilterTitle>
					<FilterContents>
						{userTypeFilter.map((userType, idx) => {
							return (
								<FilterCheckboxGroup key={idx}>
									<FilterCheckbox
										type="checkBox"
										name={userType.id}
										id={userType.id}
										checked={userType.selected}
										onChange={(e) => {
											let changedFilter = userTypeFilter.map((filter) => {
												if (filter.id === userType.id) {
													return {
														...filter,
														selected: e.currentTarget.checked,
													};
												}
												return filter;
											});
											setUserTypeFilter(changedFilter);
										}}
									/>
									<label htmlFor={userType.id}>{userType.name}</label>
								</FilterCheckboxGroup>
							);
						})}
					</FilterContents>
				</FilterRow>
				<FilterRow>
					<FilterTitle>사용자 역할</FilterTitle>
					<FilterContents>
						{userRoleFilter.map((userRole, idx) => {
							// 장비 관리자는 .env에 REACT_APP_USE_TYPE_PRODUCT가 false이면 표시하지 않는다.
							if (userRole?.use_product && !JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT)) {
								return null;
							}
							return (
								<FilterCheckboxGroup key={idx}>
									<FilterCheckbox
										type="checkBox"
										name={userRole.id}
										id={userRole.id}
										checked={userRole.selected}
										onChange={(e) => {
											let changedFilter = userRoleFilter.map((filter) => {
												if (filter.id === userRole.id) {
													return {
														...filter,
														selected: e.currentTarget.checked,
													};
												}
												return filter;
											});
											setUserRoleFilter(changedFilter);
										}}
									/>
									<label htmlFor={userRole.id}>{userRole.name}</label>
								</FilterCheckboxGroup>
							);
						})}
					</FilterContents>
				</FilterRow>
				<FilterRow>
					<FilterTitle>검색</FilterTitle>
					<FilterContents>
						<SearchKeywordInput
							type="text"
							value={searchKeyword}
							onChange={(e) => setSearchKeyword(e.currentTarget.value)}
							onKeyDown={(event) => {
								if (event.key == 'Enter') {
									onClickSearch();
								}
							}}
							placeholder="검색어를 입력해주세요."
						/>
					</FilterContents>
				</FilterRow>
			</>
		);
	};

	if (!initialized) return null;

	return (
		<Content>
			<section>
				<SectionTitle>사용자 검색</SectionTitle>
				<SearchFilterContainer>{renderFilterList()}</SearchFilterContainer>
				<SearchButtonContainer>
					<SearchButton onClick={onClickSearch}>검색</SearchButton>
				</SearchButtonContainer>
			</section>
			<ScrollSection className="mt-50px" ref={tableTopRef}>
				<TableTopContainer>
					<SectionTitleContainer>
						<SectionTitle>사용자 목록</SectionTitle>
						<SectionSubTitle>{`전체 ${userList?.pagination.total ?? '0'}명`}</SectionSubTitle>
					</SectionTitleContainer>
					<UserAddButton onClick={() => navigate('/admin/users/create')}>신규 사용자 등록</UserAddButton>
				</TableTopContainer>
				<UserTable>
					<thead>
						<tr>
							<th style={{ minWidth: '50px' }}>번호</th>
							<th style={{ minWidth: '100px' }}>아이디</th>
							<th style={{ minWidth: '90px' }}>이름</th>
							<th style={{ minWidth: '100px' }}>이메일</th>
							<th style={{ minWidth: '100px' }}>전화번호</th>
							<th style={{ minWidth: '100px' }}>로그인유형</th>
							<th style={{ minWidth: '100px' }}>사용자유형</th>
							<th style={{ minWidth: '100px' }}>사용자역할</th>
							<th style={{ minWidth: '70px' }}>항목보기</th>
						</tr>
					</thead>
					<tbody>
						{userList?.items.map((user, idx) => {
							return (
								<tr key={idx}>
									<td>{user.id}</td>
									<td>{utils.getUserLoginStr(user)}</td>
									<td>{user.name ?? '-'}</td>
									<td>{user.email ?? '-'}</td>
									<td>{user.phone ?? '-'}</td>
									<td>{LoginType.toString(user.login_type)}</td>
									<td>{UserType.toString(user.user_type)}</td>
									<td>{UserRole.toString(user.user_role)}</td>
									<td>
										<DetailButton onClick={() => onClickDetail(user.id)}>보기</DetailButton>
									</td>
								</tr>
							);
						})}
					</tbody>
				</UserTable>
				<div className="mt-24px">
					<TablePagination
						currentPage={userList?.pagination.current_page}
						lastPage={userList?.pagination.last_page}
						onPageChange={onPageChange}
					/>
				</div>
			</ScrollSection>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;
`;

const SectionTitleContainer = styled.div`
	display: flex;
	align-items: flex-end;
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const SectionSubTitle = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #808080;
`;

const SearchFilterContainer = styled.div`
	width: 100%;
	border-top: 2px solid #333333;
	margin-top: 29px;
`;

const FilterRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 51px;
	border-bottom: 1px solid #dddddd;
`;

const FilterTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 140px;
	padding-left: 20px;
	background-color: #f7f7f7;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const FilterContents = styled.ul`
	display: flex;
	flex: 1;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	flex-flow: wrap;

	border-right: 1px solid #dddddd;
	padding: 5px 15px;

	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 15px;
`;

const FilterCheckboxGroup = styled.li`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 7.5px 10px 7.5px 0;

	label {
		margin-left: 5px;
		margin-bottom: 1px;
		cursor: pointer;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}
`;

const FilterCheckbox = styled.input`
	width: 15px;
	height: 15px;
	border: 1px solid #c5c5c5;
	border-radius: 3px;
	cursor: pointer;
`;

const SearchKeywordInput = styled.input`
	width: 300px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const SearchButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 20px;
`;

const SearchButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100px;
	height: 40px;
	background-color: #00b1a6;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #008f87;
	}
`;

const ScrollSection = styled.section`
	scroll-margin-top: 110px;
`;

const TableTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const UserAddButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 122px;
	height: 36px;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const UserTable = styled.table`
	width: 100%;
	margin-top: 20px;

	border-collapse: collapse;
	border-top: 2px solid #0e498f;

	text-align: center;
	thead th {
		height: 45px;
		background-color: #f6f8fb;
		border-right: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;

		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;
		border-right: 1px solid #dddddd;
		padding: 6px 2px;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;

		word-break: break-all;
		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}
`;

const DetailButton = styled.div`
	width: 43px;
	height: 26px;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;
`;

export default UserList;
