import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import ApprovalStatus from '../constants/ApprovalStatus';
import RentalStatus from '../constants/RentalStatus';
import BoardSlug from '../constants/BoardSlug';
import UnitType from '../constants/UnitType';

import BannerImg from '../custom/images/banner.png';
import PlusWhiteCircleIconImg from '../custom/images/icon_plus_white_circle.svg';
import ArrowRightBlueIconImg from '../custom/images/icon_arrow_right_blue.svg';
import ArrowRightWhiteIconImg from '../custom/images/icon_arrow_right_white.svg';
import CommonLoginIconImg from '../custom/images/icon_common_login.svg';
import MypageIconImg from '../custom/images/icon_mypage.svg';
import BannerItem1Img from '../custom/images/banner_item1.png';

import * as utils from '../utils';
import * as api from '../apis';

const Main = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	const [noticeBoard, setNoticeBoard] = useState(null);
	const [postList, setPostList] = useState(null);
	const [bookingList, setBookingList] = useState(null);

	useEffect(() => {
		if (user) {
			let filterParams = [`page_size=2`];
			api.getMyBooking(filterParams).then((res) => {
				if (res && res.data) {
					setBookingList(res.data);
				}
			});
		}

		api.getBoards().then((res) => {
			if (res && res.data) {
				let noticeBoard = res.data.find((board) => board.slug === BoardSlug.NOTICE);
				setNoticeBoard(noticeBoard);
				api.getPosts(noticeBoard.id, [`page_size=4`]).then((res) => {
					if (res && res.data) {
						setPostList(res.data.items);
					}
				});
			}
		});
	}, [user]);

	const onClickCommonLogin = () => {
		window.location = 'https://eclass.sch.ac.kr/xn-sso/gw.php?login_type=sso&site=studio&callback_url=https%3A%2F%2Fdec.sch.ac.kr%2Flogin%2Fcallback';
	};

	const onClickMypage = () => {
		navigate('/mypage');
	};

	const onClickNoticeBoard = () => {
		navigate('/boards/notice');
	};

	const onClickPostContents = (postId) => {
		navigate(`/boards/${noticeBoard.id}/posts/${postId}`);
	};

	const onClickRoomUnitBooking = (unitId) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking/schedule/${unitId}`);
	};

	const onClickLinkItem = (url) => {
		window.open(url);
	};

	const renderBoardContentsRow = (post, idx) => {
		return (
			<BoardContentsRow key={idx} onClick={() => onClickPostContents(post.id)}>
				<BoardContentsLeft>
					<BoardCategory>{post.category?.name ?? '-'} </BoardCategory>
					<BoardContentTitle>{post.title}</BoardContentTitle>
				</BoardContentsLeft>
				<BoardContentDate>
					{post.created_at ? utils.convertDateToStr(new Date(post.created_at)) : '-'}
					<BoardArrowRightIcon src={ArrowRightBlueIconImg} />
				</BoardContentDate>
			</BoardContentsRow>
		);
	};

	const renderMyBooking = () => {
		if (!bookingList || bookingList.items.length === 0) {
			return <NoBookingList>예약 내역이 없습니다.</NoBookingList>;
		}
		return (
			<MyBookingContainer>
				{bookingList?.items.map((booking, idx) => {
					const bookingUnitType = booking.unit.unit_type_name;
					const locationDetail =
						bookingUnitType === UnitType.ROOM
							? `${booking.unit.name} ${booking.room_item.name}`
							: `${booking.unit.name}`;
					return renderBookingDetailRow(
						idx,
						bookingUnitType,
						booking.approval_status,
						booking.rental_status,
						booking.booking_date.split('~')[0],
						locationDetail
					);
				})}
			</MyBookingContainer>
		);
	};

	const renderBookingDetailRow = (idx, type, approvalStatus, rentalStatus, calendarDetail, locationDetail) => {
		return (
			<MyContentsRow key={idx}>
				<BookingStatusBox status={approvalStatus}>
					<span>{ApprovalStatus.toString(approvalStatus)}</span>
				</BookingStatusBox>
				<BookingDetailContainer>
					<BookingDetailRow>
						<BookingType type={type}>{UnitType.toString(type)}</BookingType>
						{type === UnitType.PRODUCT && (
							<RentalStatusBox status={rentalStatus}>
								{RentalStatus.toString(rentalStatus)}
							</RentalStatusBox>
						)}
					</BookingDetailRow>
					<BookingDetailRow column>
						<BookingDetailText>{calendarDetail ?? '-'}</BookingDetailText>
						<BookingLocationDetailText>{locationDetail ?? '-'}</BookingLocationDetailText>
					</BookingDetailRow>
				</BookingDetailContainer>
			</MyContentsRow>
		);
	};

	const renderReservationLinkItemTop = () => {
		const linkItems = [
			{ name: '스튜디오 A', location: '인문대 6419', unit_id: 4, image: BannerItem1Img },
			{ name: '스튜디오 B', location: '인문대 6419', unit_id: 7 },
			{ name: '자가촬영실', location: '인문과학관 6420', unit_id: 1 },
		];

		return linkItems.map((linkItem, idx) => (
			<BannerLinkItem key={idx} src={linkItem?.image} onClick={() => onClickRoomUnitBooking(linkItem.unit_id)}>
				<BannerLinkStudioName>{linkItem.name}</BannerLinkStudioName>
				<BannerLinkLocation>{linkItem.location}</BannerLinkLocation>
			</BannerLinkItem>
		));
	};

	const renderReservationLinkItemBottom = () => {
		const linkItems = [
			{ name: '자가촬영실', location: '공학관 9304', unit_id: 2 },
			{ name: '자가촬영실', location: '학예관 313', unit_id: 3 },
		];

		return linkItems.map((linkItem, idx) => (
			<BannerLinkItem key={idx} src={linkItem?.image} onClick={() => onClickRoomUnitBooking(linkItem.unit_id)}>
				<BannerLinkStudioName>{linkItem.name}</BannerLinkStudioName>
				<BannerLinkLocation>{linkItem.location}</BannerLinkLocation>
			</BannerLinkItem>
		));
	};

	const renderLinkItem = () => {
		const linkItems = [
			{ text: 'SCH LMS', url: 'https://eclass.sch.ac.kr' },
			{ text: '교육혁신원 홈페이지', url: 'https://inno.sch.ac.kr ' },
			{ text: '하이플렉스시티', url: 'http://hyflexcity.sch.ac.kr' },
			{ text: '순천향대학교', url: 'https://home.sch.ac.kr' },
		];

		return linkItems.map((linkItem, idx) => (
			<LinkItem key={idx} onClick={() => onClickLinkItem(linkItem.url)}>
				<LinkItemText>{linkItem.text}</LinkItemText>
				<LinkItemIcon src={ArrowRightWhiteIconImg} />
			</LinkItem>
		));
	};

	return (
		<Container>
			<BannerBackground src={BannerImg}>
				<BannerContainer>
					<BoardContainer>
						<BoardContentsContainer>
							<BoardTitleContainer>
								<BoardTitleLeftContainer>
									<BoardTitle>공지사항</BoardTitle>
									<BoardSubTitle>notice</BoardSubTitle>
								</BoardTitleLeftContainer>
								<PlusButtonIcon src={PlusWhiteCircleIconImg} onClick={() => onClickNoticeBoard()} />
							</BoardTitleContainer>
							<BoardContentsRowContainer>
								{postList?.map((post, idx) => {
									return renderBoardContentsRow(post, idx);
								})}
							</BoardContentsRowContainer>
						</BoardContentsContainer>
					</BoardContainer>
					<MyContainer>
						{user ? (
							<>
								<MyTopContainerLogin>
									<MyStatusTitle>내 예약현황</MyStatusTitle>
									{renderMyBooking()}
								</MyTopContainerLogin>
								<MyBottomContainer>
									<CommonLoginButton onClick={onClickMypage}>
										<LoginButtonText>마이페이지</LoginButtonText>
										<LoginButtonIcon src={MypageIconImg} />
									</CommonLoginButton>
								</MyBottomContainer>
							</>
						) : (
							<>
								<MyTopContainerLogout>
									<MyStatusTitle>내 예약현황</MyStatusTitle>
									<MyStatusLoginInfo>로그인 후 이용해 주세요.</MyStatusLoginInfo>
								</MyTopContainerLogout>
								<MyBottomContainer>
									<CommonLoginButton onClick={onClickCommonLogin}>
										<LoginButtonText>로그인</LoginButtonText>
										<LoginButtonIcon src={CommonLoginIconImg} />
									</CommonLoginButton>
								</MyBottomContainer>
							</>
						)}
					</MyContainer>
				</BannerContainer>
				<BannerLinkContainer>
					<BannerLinkTopContainer>{renderReservationLinkItemTop()}</BannerLinkTopContainer>
					<BannerLinkBottomContainer>{renderReservationLinkItemBottom()}</BannerLinkBottomContainer>
				</BannerLinkContainer>
			</BannerBackground>
			<LinkContainer>
				<LinkContent>{renderLinkItem()}</LinkContent>
			</LinkContainer>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	font-family: 'Pretendard';
`;

const BannerBackground = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	top: 0px;

	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
	}
`;

const BannerContainer = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1240px;
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		margin-top: 0;
	}
`;

const BoardContainer = styled.div`
	width: 872px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 20px;
	}

	@media only screen and (max-width: 367.98px) {
		padding-top: 0;
	}
`;

const BoardContentsContainer = styled.div`
	margin-top: 50px;
	width: 100%;
	min-height: 168px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	@media only screen and (max-width: 367.98px) {
		margin-top: 27px;
	}
`;

const BoardTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		padding: 0 0 15px 0;
	}
`;

const BoardTitleLeftContainer = styled.div`
	align-items: flex-end;
`;

const BoardTitle = styled.span`
	font-size: 40px;
	font-weight: 600;
	line-height: 47.73px;
	color: #ffffff;
	margin-right: 10px;

	@media only screen and (max-width: 367.98px) {
		font-size: 30px;
		line-height: 36px;
	}
`;

const BoardSubTitle = styled.span`
	font-size: 22px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.66em;
	opacity: 0.5;
	color: #ffffff;

	@media only screen and (max-width: 367.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const PlusButtonIcon = styled.img`
	width: 30.6px;
	height: 30.6px;
	object-fit: contain;
	cursor: pointer;
`;

const BoardContentsRowContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 431px);
	grid-template-rows: repeat(2, 180px);
	gap: 10px;
	margin-top: 20px;

	@media only screen and (max-width: 767.98px) {
		grid-template-columns: repeat(2, 50%);
		padding-right: 10px;
	}

	@media only screen and (max-width: 367.98px) {
		display: flex;
		flex-direction: column;
		padding-right: 0;
	}
`;

const BoardContentsRow = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	cursor: pointer;
	background-color: #ffffffd9;
	border-radius: 20px;
	padding: 30px;

	@media only screen and (max-width: 367.98px) {
		padding: 15px;
	}
`;

const BoardContentsLeft = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	min-width: 0;
	flex: 1;

	@media only screen and (max-width: 367.98px) {
		gap: 5px;
	}
`;

const BoardCategory = styled.span`
	flex-shrink: 0;
	font-size: 16px;
	font-weight: 600;
	line-height: 19.09px;
	color: #26539c;
	width: 70px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const BoardContentTitle = styled.span`
	font-size: 20px;
	font-weight: 600;
	line-height: 23.87px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media only screen and (max-width: 767.98px) {
		color: #333333;
	}

	@media only screen and (max-width: 367.98px) {
		font-size: 16px;
		line-height: 19.09px;
	}
`;

const BoardContentDate = styled.span`
	flex-shrink: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 19.09px;
	color: #777777;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		color: #333333cc;
	}

	@media only screen and (max-width: 367.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const BoardArrowRightIcon = styled.img`
	width: 25px;
	height: 25px;

	@media only screen and (max-width: 367.98px) {
		width: 20px;
		height: 20px;
	}
`;

const MyContainer = styled.div`
	width: 300px;
	height: 400px;
	box-sizing: border-box;
	border: 1px solid #26539c;
	border-radius: 30px;
	padding: 40px 30px;
	margin-top: 80px;
	background-color: #26539cd9;
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 40px);
		height: auto;
		padding: 40px 30px;
		margin-top: 15px;
		background-color: #26539c;
		border-radius: 30px;
	}

	@media only screen and (max-width: 367.98px) {
		padding: 30px 20px;
	}
`;

const MyTopContainerLogin = styled.div`
	margin-top: 0;
`;

const MyTopContainerLogout = styled.div`
	height: 170px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
	flex: 1;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const MyStatusTitle = styled.span`
	font-size: 30px;
	font-weight: 700;
	line-height: 35.8px;
	color: #ffffff;

	@media only screen and (max-width: 367.98px) {
		font-size: 24px;
		line-height: 29px;
	}
`;

const MyStatusLoginInfo = styled.span`
	font-size: 16px;
	font-weight: 400;
	line-height: 19.09px;
	color: #ffffff;
`;

const MyBottomContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
	}
`;

const LoginButton = styled.div`
	width: 100%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	padding: 0 30px;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		height: 60px;
	}
`;

const CommonLoginButton = styled(LoginButton)`
	background-color: #ffffff;
`;

const LoginButtonText = styled.span`
	font-size: 22px;
	font-weight: 600;
	line-height: 26.25px;
	color: #000000;
`;

const LoginButtonIcon = styled.img``;

const NoBookingList = styled.div`
	width: 100%;
	height: 221px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	color: #ffffff;
`;

const MyBookingContainer = styled.div`
	margin-top: 40px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 25px;
	}
`;

const MyContentsRow = styled.div`
	height: 73px;
	display: flex;
`;

const BookingStatusBox = styled.div`
	width: 73px;
	height: 73px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 36px;
	border: 7px solid #ffffff;

	span {
		width: 30px;
		text-align: center;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #ffffff;
	}
`;

const BookingDetailContainer = styled.div`
	margin-left: 16px;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
`;

const BookingDetailRow = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	& + & {
		margin-top: 7px;
	}

	${(props) =>
		props.column &&
		css`
			flex-direction: column;
			align-items: flex-start;
		`}
`;

const BookingType = styled.div`
	width: 49px;
	height: 26px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;

	${(props) => {
		switch (props.type) {
			case UnitType.ROOM:
				return css`
					border: 1px solid #ffffff;
					color: #ffffff;
				`;
			case UnitType.PRODUCT:
				return css`
					border: 1px solid #fc9639;
					color: #fc9639;
				`;
			default:
				return css`
					border: 1px solid #0085ce;
					color: #0085ce;
				`;
		}
	}};
`;

const RentalStatusBox = styled.div`
	margin-left: 5px;
	width: 70px;
	height: 26px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	${(props) => {
		switch (props.status) {
			case RentalStatus.RENTAL_READY:
				return css`
					background-color: #00c6ba;
				`;
			case RentalStatus.RENTAL_DELAY:
				return css`
					background-color: #e7683f;
				`;
			case RentalStatus.RETURN_DELAY:
				return css`
					background-color: #ff3535;
				`;
			case RentalStatus.RENTAL_COMPLETE:
			case RentalStatus.RETURN_COMPLETE:
				return css`
					background-color: #0d93f4;
				`;
			default:
				return css`
					background-color: #00c6ba;
				`;
		}
	}};
`;

const BookingDetailText = styled.span`
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;
`;

const BookingLocationDetailText = styled.span`
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const BannerLinkContainer = styled.div`
	margin-top: 62px;
	margin-bottom: 88px;
	display: flex;
	justify-content: center;
	gap: 25px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
		margin-top: 30px;
		margin-bottom: 29px;
		padding: 0 20px;
		flex-wrap: wrap;
		gap: 20px;
	}

	@media only screen and (max-width: 367.98px) {
		gap: 10px;
	}
`;

const BannerLinkItem = styled.div`
	width: 227px;
	height: 142px;
	background-color: #00000066;
	border: 1px solid #ffffff99;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	position: relative;
	text-align: center;

	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			filter: grayscale(100%);

			&::before {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				border-radius: 20px;
				background-color: #00000066;
			}
		`}

	@media only screen and (max-width: 367.98px) {
		height: 75px;
		border-radius: 10px;
	}
`;

const BannerLinkTopContainer = styled.div`
	display: flex;
	gap: 25px;
	width: 100%;

	@media only screen and (max-width: 767.98px) {
		gap: 20px;
	}

	@media only screen and (max-width: 367.98px) {
		gap: 10px;
	}

	@media only screen and (max-width: 320px) {
		flex-direction: column;
		${BannerLinkItem} {
			width: 100%;
		}
	}
`;

const BannerLinkBottomContainer = styled.div`
	display: flex;
	gap: 25px;
	width: 100%;

	@media only screen and (max-width: 767.98px) {
		${BannerLinkItem} {
			width: 50%;
		}
	}

	@media only screen and (max-width: 367.98px) {
		gap: 10px;
	}
`;

const BannerLinkStudioName = styled.div`
	font-size: 26px;
	font-weight: 600;
	line-height: 31px;
	color: #ffffff;
	z-index: 9999;
	white-space: nowrap;

	@media only screen and (max-width: 367.98px) {
		font-size: 18px;
		line-height: 21px;
	}
`;

const BannerLinkLocation = styled.div`
	font-size: 18px;
	font-weight: 600;
	line-height: 21px;
	color: #cccccc;
	z-index: 9999;

	@media only screen and (max-width: 367.98px) {
		font-size: 14px;
		line-height: 17px;
		white-space: nowrap;
	}
`;

const LinkContainer = styled.div`
	width: 100%;
	height: 102px;
	display: flex;
	justify-content: center;
	background-color: #15243d;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const LinkContent = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1240px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	gap: 20px;

	@media only screen and (max-width: 767.98px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 0;
	}

	@media only screen and (max-width: 367.98px) {
		gap: 10px;
	}
`;

const LinkItem = styled.div`
	flex-grow: 1;
	width: 25%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 30px;
	padding-right: 50px;
	height: 36px;
	margin: 21.5px 0;
	cursor: pointer;

	&:not(:last-of-type) {
		border-right: 1px solid #ffffff4d;
	}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding-left: 15px;
		padding-right: 20px;

		&:nth-child(1),
		&:nth-child(3) {
			border-right: 1px solid #ffffff4d;
		}

		&:nth-child(2) {
			border: none;
		}
	}

	@media only screen and (max-width: 367.98px) {
		height: 62px;
	}

	@media only screen and (max-width: 367.98px) {
		height: 36px;
		margin: 23px 0;
	}
`;

const LinkItemIcon = styled.img`
	width: 16.33px;
	height: 15.53px;

	@media only screen and (max-width: 367.98px) {
		width: 12.83px;
		height: 12.2px;
	}
`;

const LinkItemText = styled.span`
	font-size: 22px;
	font-weight: 700;
	line-height: 28.6px;
	color: #ffffff;
	word-break: keep-all;

	@media only screen and (max-width: 367.98px) {
		font-size: 16px;
		line-height: 21px;
	}
`;

export default Main;
