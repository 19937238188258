import React from 'react';
import { Route } from 'react-router-dom';

import FrontLayout from '../layouts/FrontLayout';
import Main from '../custom/Main';
import Login from '../custom/Login';
import LoginCallback from '../screens/LoginCallback';
import LoginGeneral from '../screens/LoginGeneral';
import Introduce from '../screens/Introduce';
import Board from '../screens/Board';
import PostCreate from '../screens/PostCreate';
import Post from '../screens/Post';
import Booking from '../screens/Booking';
import MyPage from '../screens/MyPage';
import BookingSchedule from '../screens/BookingSchedule';
import BookingProductSchedule from '../screens/BookingProductSchedule';
import BookingConsultSchedule from '../screens/BookingConsultSchedule';
import BookingRequest from '../screens/BookingRequest';
import BookingComplete from '../screens/BookingComplete';

export default [
	<Route
		exact
		path="/"
		key="main"
		element={
			<FrontLayout background={'#ffffff'}>
				<Main />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/login"
		key="login"
		element={
			<FrontLayout>
				<Login />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/login/general"
		key="login.general"
		element={
			<FrontLayout>
				<LoginGeneral />
			</FrontLayout>
		}
	/>,
	<Route exact path="/login/callback" key="login.callback" element={<LoginCallback />} />,
	<Route
		exact
		path="/unit/rooms/introduce"
		key="introduce"
		element={
			<FrontLayout background={'#ffffff'}>
				<Introduce />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/boards/:slug"
		key="boards"
		element={
			<FrontLayout>
				<Board />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/boards/:board_id/posts/create"
		key="boards.board_id.posts.create"
		element={
			<FrontLayout>
				<PostCreate />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/boards/:board_id/posts/:post_id"
		key="boards.board_id.posts.post_id"
		element={
			<FrontLayout>
				<Post />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/boards/:board_id/posts/:post_id/edit"
		key="boards.board_id.posts.post_id"
		element={
			<FrontLayout>
				<PostCreate />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/booking"
		key="booking"
		element={
			<FrontLayout background={'#ffffff'}>
				<Booking />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/booking/schedule/:id"
		key="booking.schedule"
		element={
			<FrontLayout>
				<BookingSchedule />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/booking/product/schedule/:id"
		key="booking.schedule"
		element={
			<FrontLayout>
				<BookingProductSchedule />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/booking/consult/schedule/:id"
		key="booking.schedule"
		element={
			<FrontLayout>
				<BookingConsultSchedule />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/booking/complete"
		key="booking.complete"
		element={
			<FrontLayout>
				<BookingComplete />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/booking/request"
		key="booking.request"
		element={
			<FrontLayout>
				<BookingRequest />
			</FrontLayout>
		}
	/>,
	<Route
		exact
		path="/mypage"
		key="mypage"
		element={
			<FrontLayout>
				<MyPage />
			</FrontLayout>
		}
	/>,
];
