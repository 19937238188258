const CustomResource = {
	univ_name: '순천향대학교',
	site_title: '교육인프라 예약시스템',
	sso_login: '',
	address1: '[31538] 충남 아산시 신창면 순천향로 22 하이플렉스교육센터 인문과학관 6407호실',
	address2: '',
	contact: 'TEL.041-530-4995, 1692',
	copyright: 'COPYRIGHT(C)2021 SOONCHUNHYANG UNIVERSITY. ALL RIGHTS RESERVED.',
};

export default CustomResource;
