import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useFilterHandler from '../../hooks/useFilterHandler';
import UnitType from '../../constants/UnitType';
import FilterType from '../../constants/FilterType';
import CategorySlug from '../../constants/CategorySlug';
import UserRole from '../../constants/UserRole';
import TablePagination from '../../components/TablePagination';
import { UserContext } from '../../contexts/UserContext';

import * as api from '../../apis';
import * as utils from '../../utils';

const UnitConsultList = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { categoryFilter, publishStatusFilter, onChangeFilterCheckbox, getUnitFilterParams } = useFilterHandler({});

	const [searchTitle, setSearchTitle] = useState('');
	const [usageStartDate, setUsageStartDate] = useState('');
	const [usageEndDate, setUsageEndDate] = useState('');

	const [pageIndex, setPageIndex] = useState(1);

	const [unitList, setUnitList] = useState(null);
	const [allChecked, setAllChecked] = useState(false);
	const [initialized, setInitialized] = useState(false);

	const tableTopRef = useRef(null);

	useEffect(() => {
		if (!unitList) return;

		setUnitList({
			...unitList,
			items: unitList.items.map((unit) => {
				return {
					...unit,
					selected: allChecked,
				};
			}),
		});
	}, [allChecked]);

	useEffect(() => {
		getUnitsWithFilter();
	}, [pageIndex]);

	const getUnitsFromApi = (filterParams) => {
		api.getUnits(filterParams)
			.then((res) => {
				if (res && res.data) {
					if (!initialized) setInitialized(true);
					setUnitList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getUnitsWithFilter = () => {
		let filterParams = getUnitFilterParams();
		filterParams.push(`unit_type=${UnitType.CONSULT}`);
		if (pageIndex) filterParams.push(`page=${pageIndex}`);
		if (searchTitle) filterParams.push(`keyword=${searchTitle}`);
		if (usageStartDate) filterParams.push(`usage_start_date=${usageStartDate}`);
		if (usageEndDate) filterParams.push(`usage_end_date=${usageEndDate}`);
		// 사용자가 관리자 권한이 없는 경우 Unit 관리 대상만 얻는다.
		if (!UserRole.isAdminRole(user?.user_role)) {
			user.unit_admins.forEach((unit) => filterParams.push(`unit_ids[]=${unit.unit_id}`));
		}
		getUnitsFromApi(filterParams);
	};

	const onClickSearch = () => {
		if (usageStartDate && !usageEndDate) {
			alert('종료일을 입력해야 합니다.');
			return;
		}
		if (!usageStartDate && usageEndDate) {
			alert('시작일을 입력해야 합니다.');
			return;
		}
		if (usageStartDate && usageEndDate && usageStartDate > usageEndDate) {
			alert('이용가능기간 시작일은 종료일 이후일 수 없습니다.');
			return;
		}
		getUnitsWithFilter();
	};

	const onClickDetail = (id) => {
		navigate(`/admin/unit/consults/${id}/edit`);
	};

	const onClickDeleteSelected = () => {
		const selectedUnitList = unitList.items.filter((val) => val.selected);
		if (selectedUnitList.length === 0) {
			alert('선택한 상담이 없습니다.');
			return;
		}

		if (!window.confirm(`선택한 ${selectedUnitList.length}개의 상담을 정말 삭제하시겠습니까?`)) return;

		let promiseArr = [];
		selectedUnitList.map((unit) => {
			promiseArr.push(api.deleteUnit(unit.id));
		});
		Promise.all(promiseArr).then(() => {
			alert('선택한 상담을 정상적으로 삭제하였습니다.');
			getUnitsWithFilter();
		});
	};

	const onPageChange = (page) => {
		setAllChecked(false);
		setPageIndex(page);
		tableTopRef.current.scrollIntoView();
	};

	const renderFilterList = () => {
		return (
			<>
				<FilterRow>
					<FilterTitle>상담명</FilterTitle>
					<FilterContents>
						<SearchTitleInput
							type="text"
							value={searchTitle}
							onChange={(e) => setSearchTitle(e.currentTarget.value)}
							placeholder="상담명을 입력해주세요."
						/>
					</FilterContents>
				</FilterRow>
				{renderCategoryFilter(CategorySlug.CONSULT_TYPE, 1)}
				{renderCategoryFilter(CategorySlug.CONSULT_TYPE, 2)}
				{renderCategoryFilter(CategorySlug.CONSULT_PLACE, 1)}
				{renderCategoryFilter(CategorySlug.CONSULT_PLACE, 2)}
				{renderCategoryFilter(CategorySlug.CONSULT_PLACE, 3)}
				<FilterRow>
					<FilterTitle>공개 상태</FilterTitle>
					<FilterContents>
						{renderFilterCheckbox(publishStatusFilter, FilterType.PUBLISH_STATUS)}
					</FilterContents>
				</FilterRow>
				<FilterRow>
					<FilterTitle>이용가능기간</FilterTitle>
					<FilterContents>
						<SearchDateInput
							type="date"
							value={usageStartDate}
							onChange={(e) => setUsageStartDate(e.currentTarget.value)}
						/>
						<SearchDateInputSeparator>~</SearchDateInputSeparator>
						<SearchDateInput
							type="date"
							value={usageEndDate}
							onChange={(e) => setUsageEndDate(e.currentTarget.value)}
						/>
					</FilterContents>
				</FilterRow>
			</>
		);
	};

	const renderCategoryFilter = (slug, depth) => {
		if (categoryFilter.length === 0) return null;
		let filter = categoryFilter
			.find((category) => category.slug === slug)
			.filters.find((categoryFilter) => categoryFilter.depth === depth);

		return (
			<FilterRow>
				<FilterTitle>{filter.title}</FilterTitle>
				<FilterContents>{renderFilterCheckbox(filter.category_items, FilterType.CATEGORY)}</FilterContents>
			</FilterRow>
		);
	};

	const renderFilterCheckbox = (filterList, type) => {
		return filterList?.map((filter, idx) => {
			const id = `${type}_${filter.id}_${filter.name}`;
			return (
				<FilterCheckboxGroup key={idx}>
					<FilterCheckbox
						key={idx}
						type="checkBox"
						name={id}
						id={id}
						checked={filter?.selected}
						onChange={(e) => {
							onChangeFilterCheckbox(type, filter.id, e.currentTarget.checked);
						}}
					/>
					<label htmlFor={id}>{filter.name}</label>
				</FilterCheckboxGroup>
			);
		});
	};

	if (!initialized) return null;

	return (
		<Content>
			<section>
				<SearchUnitTopContainer>
					<SectionTitle>상담 검색</SectionTitle>
					{UserRole.isAdminRole(user?.user_role) && (
						<UnitAddButton onClick={() => navigate('/admin/unit/consults/create')}>
							신규 상담 등록
						</UnitAddButton>
					)}
				</SearchUnitTopContainer>
				<SearchFilterContainer>{renderFilterList()}</SearchFilterContainer>
				<SearchButtonContainer>
					<SearchButton onClick={onClickSearch}>검색</SearchButton>
				</SearchButtonContainer>
			</section>
			<ScrollSection className="mt-50px" ref={tableTopRef}>
				<SectionTitle>상담 목록</SectionTitle>
				<SectionSubTitle>{`전체 ${unitList?.pagination.total ?? '0'}개`}</SectionSubTitle>
				<TableTopContainer>
					{UserRole.isAdminRole(user?.user_role) && (
						<DeleteButton onClick={onClickDeleteSelected}>선택삭제</DeleteButton>
					)}
				</TableTopContainer>
				<SearchResultTable>
					<thead>
						<tr>
							<th style={{ minWidth: '50px' }}>
								<FilterCheckbox
									type="checkBox"
									checked={allChecked}
									onChange={(e) => setAllChecked(e.currentTarget.checked)}
								/>
							</th>
							<th style={{ minWidth: '50px' }}>번호</th>
							<th style={{ minWidth: '100px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_TYPE, 1)}
							</th>
							<th style={{ minWidth: '120px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_TYPE, 2)}
							</th>
							<th style={{ minWidth: '80px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_PLACE, 1)}
							</th>
							<th style={{ minWidth: '80px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_PLACE, 2)}
							</th>
							<th style={{ minWidth: '80px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_PLACE, 3)}
							</th>
							<th style={{ minWidth: '120px' }}>상담명</th>
							<th style={{ minWidth: '80px' }}>상담자(명)</th>
							<th style={{ minWidth: '60px' }}>공개상태</th>
							<th style={{ minWidth: '170px' }}>이용가능기간</th>
							<th style={{ minWidth: '100px' }}>관리자</th>
							<th style={{ minWidth: '70px' }}>항목보기</th>
						</tr>
					</thead>
					<tbody>
						{unitList?.items.map((unit, idx) => {
							return (
								<tr key={idx}>
									<td>
										<FilterCheckbox
											type="checkBox"
											checked={unit.selected}
											onChange={(e) =>
												setUnitList({
													...unitList,
													items: unitList.items.map((unit, dataIdx) => {
														if (idx === dataIdx) {
															return {
																...unit,
																selected: e.currentTarget.checked,
															};
														}
														return unit;
													}),
												})
											}
										/>
									</td>
									<td>{unit.id}</td>
									<td>{utils.getUnitCategoryName(unit, CategorySlug.CONSULT_TYPE, 1)}</td>
									<td>{utils.getUnitCategoryName(unit, CategorySlug.CONSULT_TYPE, 2)}</td>
									<td>{utils.getUnitCategoryName(unit, CategorySlug.CONSULT_PLACE, 1)}</td>
									<td>{utils.getUnitCategoryName(unit, CategorySlug.CONSULT_PLACE, 2)}</td>
									<td>{utils.getUnitCategoryName(unit, CategorySlug.CONSULT_PLACE, 3)}</td>
									<td>{unit.name ?? '-'}</td>
									<td>{unit.room_items?.length ?? '-'}</td>
									<td>{unit.published ? '공개' : '비공개'}</td>
									<td>
										{unit?.enable_schedule?.usage_start_date ?? '-'}~
										{unit?.enable_schedule?.usage_end_date ?? '-'}
									</td>
									<td>
										{unit?.unit_admins
											.map((unitAdmin) => `${unitAdmin.name}(${unitAdmin.user_login})`)
											.join(',') ?? '-'}
									</td>
									<td>
										<DetailButton onClick={() => onClickDetail(unit.id)}>보기</DetailButton>
									</td>
								</tr>
							);
						})}
					</tbody>
					{UserRole.isAdminRole(user?.user_role) && (
						<tfoot>
							<TableFoot>
								<td colSpan="100%">
									<DeleteButton onClick={onClickDeleteSelected}>선택삭제</DeleteButton>
								</td>
							</TableFoot>
						</tfoot>
					)}
				</SearchResultTable>
				<div className="mt-24px">
					<TablePagination
						currentPage={unitList?.pagination.current_page}
						lastPage={unitList?.pagination.last_page}
						onPageChange={onPageChange}
					/>
				</div>
			</ScrollSection>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;
`;

const SearchUnitTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const SectionSubTitle = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #808080;
`;

const UnitAddButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 122px;
	height: 36px;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const SearchFilterContainer = styled.div`
	width: 100%;
	border-top: 2px solid #333333;
	margin-top: 29px;
`;

const FilterRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 51px;
	border-bottom: 1px solid #dddddd;
`;

const FilterTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 140px;
	padding-left: 20px;
	background-color: #f7f7f7;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const FilterContents = styled.ul`
	display: flex;
	flex: 1;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	flex-flow: wrap;

	border-right: 1px solid #dddddd;
	padding: 5px 15px;

	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 15px;
`;

const FilterCheckboxGroup = styled.li`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 7.5px 10px 7.5px 0;

	label {
		margin-left: 5px;
		margin-bottom: 1px;
		cursor: pointer;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #666666;
	}
`;

const FilterCheckbox = styled.input`
	width: 15px;
	height: 15px;
	border: 1px solid #c5c5c5;
	border-radius: 3px;
	cursor: pointer;
`;

const SearchTitleInput = styled.input`
	width: 300px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const SearchDateInput = styled.input`
	width: 150px;
	height: 35px;
	margin: 7.5px 0;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const SearchDateInputSeparator = styled.span`
	margin: 0 10px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const SearchButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 20px;
`;

const SearchButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100px;
	height: 40px;
	background-color: #00b1a6;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #008f87;
	}
`;

const ScrollSection = styled.section`
	scroll-margin-top: 110px;
`;

const TableTopContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 45px;
	margin-top: 20px;
	background: #f7f7f7;
	border-top: 1px solid #dddddd;
`;

const SearchResultTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #0e498f;

	text-align: center;
	thead th {
		height: 45px;
		background-color: #f6f8fb;
		border-right: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;

		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;
		border-right: 1px solid #dddddd;
		padding: 6px 2px;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;

		word-break: break-all;
		:first-child {
			border-left: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}
`;

const TableFoot = styled.tr`
	text-align: left;
`;

const DetailButton = styled.div`
	width: 43px;
	height: 26px;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	background-color: #2f5a8c;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #ffffff;
`;

const DeleteButton = styled.div`
	width: 60px;
	height: 28px;
	margin-left: 8px;

	background-color: #ffffff;
	border: 1px solid #333333;
	border-radius: 5px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #333333;

	&:hover {
		border: 1px solid #2b62ab;
		color: #2b62ab;
	}
`;

export default UnitConsultList;
