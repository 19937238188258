import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import DashboardSummaryCard from '../../components/admin/DashboardSummaryCard';
import DashboardCalendar from '../../components/admin/DashboardCalendar';
import { UserContext } from '../../contexts/UserContext';
import UnitType from '../../constants/UnitType';
import ApprovalStatus from '../../constants/ApprovalStatus';
import UserRole from '../../constants/UserRole';
import * as api from '../../apis';
import * as utils from '../../utils';

const DashboardConsult = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const [data, setData] = useState(null);
	const [bookingTotalCount, setBookingTotalCount] = useState(0);
	const graphColorList = ['#acc4e5', '#aaacaf', '#afdde0', '#c4c5e8', '#e18a9f', '#899cba'];

	useEffect(() => {
		api.getDashboard(UnitType.CONSULT)
			.then((res) => {
				if (res && res.data) {
					const statistics = res.data?.room_type?.statistics;
					let totalCount = 0;
					if (statistics) {
						Object.keys(statistics).forEach((key) => {
							totalCount += statistics[key];
						});
						setBookingTotalCount(totalCount);
					}
					setData(res.data);
				}
			})
			.catch((err) => console.error(err));
	}, []);

	const getTodayTotal = () => {
		return (
			(data?.today?.pending ?? 0) +
			(data?.today?.approved ?? 0) +
			(data?.today?.rejected ?? 0) +
			(data?.today?.canceled ?? 0)
		);
	};

	const onClickCard = (status, today) => {
		navigate(`/admin/booking/consults`, {
			state: {
				filter: {
					approval_status: status,
					usage_date: today ? utils.convertDateToStr(new Date()) : null,
				},
			},
		});
	};

	return (
		<Content>
			<section>
				<SectionTitle>오늘의 예약현황</SectionTitle>
				<Row className="mt-20px">
					<DashboardSummaryCard
						title="승인대기(누적)"
						background="#19B4B5"
						border="#19B4B5"
						color="#FFFFFF"
						count={data?.total?.pending ?? 0}
						large
						onClick={(e) => onClickCard(ApprovalStatus.PENDING, false)}
					/>
					<DashboardSummaryCard
						title="신청"
						background="#FFFFFF"
						border="#BCD1EE"
						color="#2460B1"
						count={getTodayTotal()}
						onClick={(e) => onClickCard(null, true)}
					/>
					<DashboardSummaryCard
						title="승인대기"
						background="#FFFFFF"
						border="#BFC3E8"
						color="#6B64B5"
						count={data?.today?.pending ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.PENDING, true)}
					/>
					<DashboardSummaryCard
						title="승인완료"
						background="#FFFFFF"
						border="#ACD7EB"
						color="#306D8A"
						count={data?.today?.approved ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.APPROVED, true)}
					/>
					<DashboardSummaryCard
						title="승인거부"
						background="#FFFFFF"
						border="#F6CBC6"
						color="#D2674F"
						count={data?.today?.rejected ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.REJECTED, true)}
					/>
					<DashboardSummaryCard
						title="예약취소"
						background="#FFFFFF"
						border="#EFC3CD"
						color="#C34B66"
						count={data?.today?.canceled ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.CANCELED, true)}
					/>
				</Row>
			</section>
			<section className="mt-50px">
				<SectionTitle>예약 캘린더</SectionTitle>
				<DashboardCalendar unitType={UnitType.CONSULT} />
			</section>
			{UserRole.isAdminRole(user?.user_role) && (
				<section className="mt-50px">
					<SectionTitle>전체 예약 현황</SectionTitle>
					<Row className="mt-20px">
						<Table>
							<thead>
								<tr>
									<th>상태</th>
									<th>신청</th>
									<th>승인대기</th>
									<th>승인완료</th>
									<th>승인거부</th>
									<th>예약취소</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>이번주</th>
									<td>{data?.overall?.weekly_status_total ?? '0'}</td>
									<td>{data?.overall?.weekly_status?.pending ?? '0'}</td>
									<td>{data?.overall?.weekly_status?.approved ?? '0'}</td>
									<td>{data?.overall?.weekly_status?.rejected ?? '0'}</td>
									<td>{data?.overall?.weekly_status?.canceled ?? '0'}</td>
								</tr>
								<tr>
									<th>이번달</th>
									<td>{data?.overall?.monthly_status_total ?? '0'}</td>
									<td>{data?.overall?.monthly_status?.pending ?? '0'}</td>
									<td>{data?.overall?.monthly_status?.approved ?? '0'}</td>
									<td>{data?.overall?.monthly_status?.rejected ?? '0'}</td>
									<td>{data?.overall?.monthly_status?.canceled ?? '0'}</td>
								</tr>
							</tbody>
						</Table>
					</Row>
				</section>
			)}
			{UserRole.isAdminRole(user?.user_role) && (
				<section className="mt-50px">
					<SectionTitleContainer>
						<SectionTitle>상담 유형별 이달의 신청 현황</SectionTitle>
						<SectionSubTitle>{data?.room_type?.date ?? '-'}</SectionSubTitle>
					</SectionTitleContainer>
					{data?.room_type?.statistics && (
						<Row className="mt-20px">
							<StatisticsGraphContainer>
								{Object.keys(data?.room_type?.statistics).map((key, idx) => {
									const graphColor = graphColorList[idx % graphColorList.length];
									const barWidth = String(
										(parseInt(data?.room_type?.statistics[key]) / bookingTotalCount) * 100
									);
									return (
										<StatisticsGraphRow key={idx}>
											<StatisticsTitle>{key}</StatisticsTitle>
											<StatisticsBarContainer>
												<StatisticsBar width={barWidth ?? '0'} color={graphColor} />
												<StatisticsCount color={graphColor}>
													{data?.room_type?.statistics[key] ?? 0}
												</StatisticsCount>
											</StatisticsBarContainer>
										</StatisticsGraphRow>
									);
								})}
							</StatisticsGraphContainer>
						</Row>
					)}
				</section>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;
`;

const SectionTitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const SectionSubTitle = styled.span`
	margin-left: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	color: #666666;
`;

const Row = styled.div`
	display: flex;
	width: 100%;
`;

const Table = styled.table`
	width: 100%;

	border-collapse: collapse;
	border: 1px solid #dddddd;
	border-top: 2px solid #0e498f;
	text-align: center;
	thead th {
		width: calc(100% / 6);
		height: 45px;
		border: 1px solid #dddddd;
		background-color: #f6f8fb;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;
	}
	tbody tr th {
		height: 45px;
		border: 1px solid #dddddd;
		background-color: #f8f8f8;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #0e498f;
	}
	tbody tr td {
		height: 45px;
		border: 1px solid #dddddd;

		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #333333;
	}
`;

const StatisticsGraphContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px 38px 20px 38px;

	border: 1px solid #dddddd;
`;

const StatisticsGraphRow = styled.div`
	display: flex;
	align-items: center;
	height: 45px;
`;

const StatisticsTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 130px;
	height: 100%;

	box-sizing: content-box;
	padding-right: 34px;
	border-right: 1px solid #dddddd;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #000000;
`;

const StatisticsBarContainer = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const StatisticsBar = styled.div`
	height: 30px;
	background-color: ${(props) => props.color};
	${(props) =>
		props.width &&
		css`
			width: ${props.width}%;
		`}
`;

const StatisticsCount = styled.div`
	width: 20px;
	margin-left: 9px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${(props) => props.color};
`;

export default DashboardConsult;
