import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { UserContext } from '../contexts/UserContext';
import useSizeDetector from '../hooks/useSizeDetector';
import TimeSelect from '../components/TimeSelect';
import UnitTypeTab from '../components/UnitTypeTab';
import CategoryTab from '../components/CategoryTab';
import CategorySlug from '../constants/CategorySlug';
import UnitType from '../constants/UnitType';

import TitleTailImg from '../assets/images/common/title_tail.svg';
import TitleTailMobileImg from '../assets/images/common/title_tail_mobile.svg';
import BreadcrumbHomeImg from '../assets/images/common/breadcrumb_home.svg';
import BreadcrumbSeparatorImg from '../assets/images/common/breadcrumb_separator.svg';
import TimeFilterResetImg from '../assets/images/screens/Booking/time_filter_reset.svg';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import * as api from '../apis';
import * as utils from '../utils';

const Booking = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const { user } = useContext(UserContext);
	const { isDesktop } = useSizeDetector();
	const [unitType, setUnitType] = useState(UnitType.ROOM);
	const [categoryList, setCategoryList] = useState(null);
	const [typeCategoryItemList, setTypeCategoryItemList] = useState([]);
	const [placeCategoryItemList, setPlaceCategoryItemList] = useState(null);
	const [initPlaceCategoryItem, setInitPlaceCategoryItem] = useState(null);
	const [typeCategoryItemId, setTypeCategoryItemId] = useState(null);

	const [placeCategoryFilter, setPlaceCategoryFilter] = useState([]);
	const [placeCategoryDepth2Filter, setPlaceCategoryDepth2Filter] = useState([]);
	const [typeCategoryFilter, setTypeCategoryFilter] = useState([]);
	// Room Filter
	const [roomUsageStartDateFilter, setRoomUsageStartDateFilter] = useState(null);
	const [startTimeFilter, setStartTimeFilter] = useState(null);
	const [endTimeFilter, setEndTimeFilter] = useState(null);
	// Product Filter
	const [productUsageStartDateFilter, setProductUsageStartDateFilter] = useState(null);
	const [productUsageEndDateFilter, setProductUsageEndDateFilter] = useState(null);

	const [unitList, setUnitList] = useState(null);

	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (!user) {
			navigate('/login');
			return;
		}

		// 메인 페이지에서 unit_type을 바로 지정해서 연결하는 경우
		let defaultUnitType = UnitType.ROOM;
		if (location.state?.unit_type) {
			defaultUnitType = location.state?.unit_type;
		}

		// QueryString으로 unit_type을 바로 지정해서 연결하는 경우
		if (searchParams.get('unit_type')) {
			defaultUnitType = searchParams.get('unit_type');
		}

		// location state나 querystring으로 잘못된 것이 넘어오면 room으로 기본 값을 변경한다.
		switch (defaultUnitType) {
			case UnitType.ROOM:
			case UnitType.PRODUCT:
			case UnitType.CONSULT:
				break;
			default:
				defaultUnitType = UnitType.ROOM;
				break;
		}

		api.getCategories()
			.then((res) => {
				if (res && res.data) {
					setUnitType(defaultUnitType);
					setCategoryList(res.data);
					setInitialized(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!categoryList) {
			return;
		}
		let categorySlug = CategorySlug.TYPE;
		switch (unitType) {
			case UnitType.ROOM:
				categorySlug = CategorySlug.TYPE;
				break;
			case UnitType.PRODUCT:
				categorySlug = CategorySlug.PRODUCT_TYPE;
				break;
			case UnitType.CONSULT:
				categorySlug = CategorySlug.CONSULT_TYPE;
				break;
		}
		let typeCategory = categoryList.find((category) => category.slug === categorySlug);
		setTypeCategoryItemList(typeCategory.items);

		let placeCategorySlug = CategorySlug.PLACE;
		switch (unitType) {
			case UnitType.ROOM:
				placeCategorySlug = CategorySlug.PLACE;
				break;
			case UnitType.PRODUCT:
				placeCategorySlug = CategorySlug.PRODUCT_PLACE;
				break;
			case UnitType.CONSULT:
				placeCategorySlug = CategorySlug.CONSULT_PLACE;
				break;
		}
		let placeCategory = categoryList.find((category) => category.slug === placeCategorySlug);
		setPlaceCategoryItemList(placeCategory.items);

		let defaultTypeCategoryItemId = typeCategory.items[0].id;
		// QueryString으로 Type Category 1depth 분류를 지정하는 경우
		if (searchParams.get('type_category_id')) {
			const defaultTypeCategory = typeCategory.items.find(
				(typeCategoryItem) => typeCategoryItem.id === Number(searchParams.get('type_category_id'))
			);
			if (defaultTypeCategory) {
				defaultTypeCategoryItemId = defaultTypeCategory.id;
			}
		}
		setTypeCategoryItemId(defaultTypeCategoryItemId);

		// 메인 페이지에서 직접 Place Category 분류를 지정하는 경우
		if (location.state?.place_category_id) {
			const initPlaceCategory = placeCategory.items.find(
				(placeCategory) => placeCategory.id === location.state?.place_category_id
			);
			setInitPlaceCategoryItem(initPlaceCategory);
		}
	}, [unitType, categoryList]);

	useEffect(() => {
		if (!initPlaceCategoryItem) {
			return;
		}

		onClickPlaceCategoryItem(initPlaceCategoryItem);

		return () => {
			setInitPlaceCategoryItem(null);
		};
	}, [initPlaceCategoryItem]);

	useEffect(() => {
		setTypeCategoryItemList(
			typeCategoryItemList.map((typeCategoryItem) => {
				return {
					...typeCategoryItem,
					children: typeCategoryItem.children.map((typeCategoryItemChild) => {
						return {
							...typeCategoryItemChild,
							selected: false,
						};
					}),
				};
			})
		);
		setTypeCategoryFilter([]);
	}, [typeCategoryItemId]);

	useEffect(() => {
		if (
			placeCategoryFilter ||
			placeCategoryDepth2Filter ||
			typeCategoryFilter ||
			startTimeFilter ||
			endTimeFilter ||
			roomUsageStartDateFilter ||
			productUsageStartDateFilter ||
			productUsageEndDateFilter
		) {
			filterChange();
		}
	}, [
		placeCategoryFilter,
		placeCategoryDepth2Filter,
		typeCategoryFilter,
		startTimeFilter,
		endTimeFilter,
		roomUsageStartDateFilter,
		productUsageStartDateFilter,
		productUsageEndDateFilter,
	]);

	const filterChange = () => {
		if (typeCategoryItemId === null) return;

		let filterParams = [`published[]=true`, `only_enabled=true`, `page_size=${Number.MAX_SAFE_INTEGER}`];
		filterParams.push(`unit_type=${unitType}`);
		filterParams.push(`category_item_ids[0][]=${typeCategoryItemId}`);
		if (placeCategoryFilter.length !== 0) {
			placeCategoryFilter.forEach((categoryItemId) =>
				filterParams.push(`category_item_ids[1][]=${categoryItemId}`)
			);
		}
		if (placeCategoryDepth2Filter.length !== 0) {
			placeCategoryDepth2Filter.forEach((categoryItemId) =>
				filterParams.push(`category_item_ids[2][]=${categoryItemId}`)
			);
		}
		if (typeCategoryFilter.length !== 0) {
			typeCategoryFilter.forEach((categoryItemId) =>
				filterParams.push(`category_item_ids[3][]=${categoryItemId}`)
			);
		}

		// 유형에 따른 추가 필터 지정
		switch (unitType) {
			case UnitType.ROOM:
			case UnitType.CONSULT:
				if (roomUsageStartDateFilter) filterParams.push(`usage_start_date=${roomUsageStartDateFilter}`);
				if (startTimeFilter) filterParams.push(`booking_start_time=${startTimeFilter}`);
				if (endTimeFilter) filterParams.push(`booking_end_time=${endTimeFilter}`);
				break;
			case UnitType.PRODUCT:
				if (productUsageStartDateFilter) filterParams.push(`usage_start_date=${productUsageStartDateFilter}`);
				if (productUsageEndDateFilter) filterParams.push(`usage_end_date=${productUsageEndDateFilter}`);
				break;
		}

		getUnitsFromApi(filterParams);
	};

	const getUnitsFromApi = (filterParams) => {
		api.getUnits(filterParams)
			.then((res) => {
				if (res && res.data) {
					setUnitList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const changeCategoryFilter = (categoryItem, target, setter) => {
		if (categoryItem.selected) {
			setter(target.filter((categoryId) => categoryId !== categoryItem.id));
		} else {
			let newPlaceCategoryFilter = target.map((categoryId) => categoryId);
			newPlaceCategoryFilter.push(categoryItem.id);
			setter(newPlaceCategoryFilter);
		}
	};

	const getUnitTypeTabs = () => {
		let unitTypeTabs = [{ key: UnitType.ROOM, text: '시설예약' }];
		if (JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT ?? false)) {
			unitTypeTabs.push({ key: UnitType.PRODUCT, text: '장비예약' });
		}
		if (JSON.parse(process.env.REACT_APP_USE_TYPE_CONSULT ?? false)) {
			unitTypeTabs.push({ key: UnitType.CONSULT, text: '상담예약' });
		}
		return unitTypeTabs;
	};

	const onClickHome = () => {
		navigate('/');
	};

	const onClickPlaceCategoryItem = (categoryItem) => {
		setPlaceCategoryItemList(
			placeCategoryItemList.map((placeCategoryItem) => {
				if (placeCategoryItem.id === categoryItem.id) {
					changeCategoryFilter(placeCategoryItem, placeCategoryFilter, setPlaceCategoryFilter);
					return {
						...placeCategoryItem,
						selected: placeCategoryItem.selected ? false : true,
					};
				}
				return placeCategoryItem;
			})
		);
	};

	const onCheckPlaceCategoryItemDepth2 = (categoryItem) => {
		setPlaceCategoryItemList(
			placeCategoryItemList.map((placeCategoryItem) => {
				return {
					...placeCategoryItem,
					children: placeCategoryItem.children.map((childDepth2) => {
						if (childDepth2.id === categoryItem.id) {
							let childDepth2Selected = childDepth2.selected ? false : true;

							// depth3 자식의 선택 상태 변경
							let newPlaceCategoryDepth2Filter = placeCategoryDepth2Filter.map(
								(categoryId) => categoryId
							);
							// depth3가 없는 경우 필터에 depth2의 id를 넣거나 뺀다.
							if (childDepth2.children.length === 0) {
								if (childDepth2Selected) {
									newPlaceCategoryDepth2Filter.push(childDepth2.id);
								} else {
									newPlaceCategoryDepth2Filter = newPlaceCategoryDepth2Filter.filter(
										(categoryId) => categoryId !== childDepth2.id
									);
								}
							}
							childDepth2.children.forEach((childDepth3) => {
								childDepth3.selected = childDepth2Selected;

								if (childDepth2Selected) {
									newPlaceCategoryDepth2Filter.push(childDepth3.id);
								} else {
									newPlaceCategoryDepth2Filter = newPlaceCategoryDepth2Filter.filter(
										(categoryId) => categoryId !== childDepth3.id
									);
								}
							});
							setPlaceCategoryDepth2Filter(newPlaceCategoryDepth2Filter);

							return {
								...childDepth2,
								selected: childDepth2Selected,
								indeterminate: false,
							};
						}
						return childDepth2;
					}),
				};
			})
		);
	};

	const onCheckPlaceCategoryItemDepth3 = (categoryItem) => {
		setPlaceCategoryItemList(
			placeCategoryItemList.map((placeCategoryItem) => {
				return {
					...placeCategoryItem,
					children: placeCategoryItem.children.map((childDepth2) => {
						let depth3Children = childDepth2.children.map((childDepth3) => {
							if (childDepth3.id === categoryItem.id) {
								changeCategoryFilter(
									childDepth3,
									placeCategoryDepth2Filter,
									setPlaceCategoryDepth2Filter
								);
								return {
									...childDepth3,
									selected: childDepth3.selected ? false : true,
								};
							}
							return childDepth3;
						});

						// 모두 선택 되어있거나, 선택 해제 되어있다면 부모의 선택 상태를 바꿔준다.
						let childDepth2Selected = childDepth2.selected;
						let indeterminate = false;
						let depth3ChildrenSelectedCount = depth3Children.filter((child) => child.selected).length;
						if (depth3ChildrenSelectedCount === 0) {
							childDepth2Selected = false;
						} else if (depth3ChildrenSelectedCount === depth3Children.length) {
							childDepth2Selected = true;
						} else {
							childDepth2Selected = true;
							indeterminate = true;
						}

						return {
							...childDepth2,
							selected: childDepth2Selected,
							indeterminate,
							children: depth3Children,
						};
					}),
				};
			})
		);
	};

	const onClickTypeCategoryItem = (categoryItem) => {
		setTypeCategoryItemList(
			typeCategoryItemList.map((typeCategoryItem) => {
				if (typeCategoryItem.id === typeCategoryItemId) {
					return {
						...typeCategoryItem,
						children: typeCategoryItem.children.map((typeCategoryItemChild) => {
							if (typeCategoryItemChild.id === categoryItem.id) {
								changeCategoryFilter(typeCategoryItemChild, typeCategoryFilter, setTypeCategoryFilter);
								return {
									...typeCategoryItemChild,
									selected: typeCategoryItemChild.selected ? false : true,
								};
							}
							return typeCategoryItemChild;
						}),
					};
				}
				return typeCategoryItem;
			})
		);
	};

	const onClickResetTimeFilter = () => {
		setStartTimeFilter(null);
		setEndTimeFilter(null);
	};

	const onClickUnit = (id) => {
		switch (unitType) {
			case UnitType.ROOM:
				navigate(`/booking/schedule/${id}`, {
					state: {
						unit_id: id,
						filter: {
							usage_start_date: roomUsageStartDateFilter,
						},
					},
				});
				break;
			case UnitType.PRODUCT:
				navigate(`/booking/product/schedule/${id}`, {
					state: {
						unit_id: id,
					},
				});
				break;
			case UnitType.CONSULT:
				navigate(`/booking/consult/schedule/${id}`, {
					state: {
						unit_id: id,
						filter: {
							usage_start_date: roomUsageStartDateFilter,
						},
					},
				});
				break;
		}
	};

	const renderPlaceCategoryItem = (categoryItem, idx) => {
		return (
			<PlaceCategoryItemContainer key={idx}>
				<PlaceCategoryItem
					title={categoryItem.name}
					selected={categoryItem.selected}
					noChildren={categoryItem.children.length === 0}
					onClick={(e) => onClickPlaceCategoryItem(categoryItem)}
				>
					<CategoryItemName selected={categoryItem.selected}>{categoryItem.name}</CategoryItemName>
				</PlaceCategoryItem>
				{categoryItem.selected && categoryItem.children.length > 0 && (
					<CategoryChildrenTree>
						{categoryItem.children.map((categoryChildDepth2, idx) => {
							return (
								<CategoryChild key={idx}>
									<CategoryChildDepth2
										selected={categoryChildDepth2.selected}
										title={categoryChildDepth2.name}
									>
										{categoryChildDepth2.indeterminate ? (
											<StyledIndeterminateCheckBoxOutlinedIcon
												onClick={(e) => {
													onCheckPlaceCategoryItemDepth2(categoryChildDepth2);
												}}
											/>
										) : (
											<FilterCheckbox
												type="checkBox"
												name={categoryChildDepth2.id}
												id={categoryChildDepth2.id}
												checked={categoryChildDepth2.selected}
												onChange={(e) => onCheckPlaceCategoryItemDepth2(categoryChildDepth2)}
											/>
										)}
										<CategoryChildDepth2Label
											htmlFor={categoryChildDepth2.id}
											selected={categoryChildDepth2.selected}
										>
											{categoryChildDepth2.name}
										</CategoryChildDepth2Label>
									</CategoryChildDepth2>
									{categoryChildDepth2.children.map((categoryChildDepth3, _) => {
										return (
											<CategoryChildDepth3 key={_} title={categoryChildDepth3.name}>
												<FilterCheckbox
													type="checkBox"
													name={categoryChildDepth3.id}
													id={categoryChildDepth3.id}
													checked={categoryChildDepth3.selected}
													onChange={(e) =>
														onCheckPlaceCategoryItemDepth3(categoryChildDepth3)
													}
												/>
												<CategoryChildDepth3Label
													htmlFor={categoryChildDepth3.id}
													selected={categoryChildDepth3.selected}
												>
													{categoryChildDepth3.name}
												</CategoryChildDepth3Label>
											</CategoryChildDepth3>
										);
									})}
								</CategoryChild>
							);
						})}
					</CategoryChildrenTree>
				)}
			</PlaceCategoryItemContainer>
		);
	};

	const renderTypeCategoryItem = (categoryItem, idx) => {
		return (
			<CategoryItem
				title={categoryItem.name}
				key={idx}
				selected={categoryItem.selected}
				onClick={(e) => onClickTypeCategoryItem(categoryItem)}
			>
				<CategoryItemName selected={categoryItem.selected}>{categoryItem.name}</CategoryItemName>
			</CategoryItem>
		);
	};

	const renderUnit = (unit, idx) => {
		switch (unitType) {
			case UnitType.ROOM:
				return renderRoomUnit(unit, idx);
			case UnitType.PRODUCT:
				return renderProductUnit(unit, idx);
			case UnitType.CONSULT:
				return renderConsultUnit(unit, idx);
		}

		return null;
	};

	const renderRoomUnit = (unit, idx) => {
		return (
			<Unit key={idx} onClick={(e) => onClickUnit(unit.id)}>
				{unit.thumbnail ? (
					<UnitThumbnail src={`${process.env.REACT_APP_API_URL}/storage/files/${unit.thumbnail?.filename}`} />
				) : (
					<EmptyUnitThumbnail />
				)}
				<UnitInfoContainer>
					<UnitPlaceCategoryName>
						{utils.getUnitCategoryName(unit, CategorySlug.PLACE, 1)}
					</UnitPlaceCategoryName>
					<UnitPlaceCategoryName>
						{`${utils.getUnitCategoryName(unit, CategorySlug.PLACE, 2)} ${utils.getUnitCategoryName(
							unit,
							CategorySlug.PLACE,
							3
						)}`}
					</UnitPlaceCategoryName>
					<UnitName>{unit.name}</UnitName>
				</UnitInfoContainer>
			</Unit>
		);
	};

	const renderProductUnit = (unit, idx) => {
		return (
			<Unit key={idx} onClick={(e) => onClickUnit(unit.id)}>
				{unit.thumbnail ? (
					<UnitThumbnail src={`${process.env.REACT_APP_API_URL}/storage/files/${unit.thumbnail?.filename}`} />
				) : (
					<EmptyUnitThumbnail />
				)}
				<UnitInfoContainer>
					<UnitPlaceCategoryName>
						{`수량 : ${unit.product_items?.length ?? 0}개`}
						{unit.remain_item_count !== null && (
							<EnableProductItems>{` (${unit.remain_item_count}개 예약가능)`}</EnableProductItems>
						)}
					</UnitPlaceCategoryName>
					<UnitPlaceCategoryName>
						{`${utils.getUnitCategoryName(
							unit,
							CategorySlug.PRODUCT_PLACE,
							1
						)} | ${utils.getUnitCategoryName(
							unit,
							CategorySlug.PRODUCT_PLACE,
							2
						)} | ${utils.getUnitCategoryName(unit, CategorySlug.PRODUCT_PLACE, 3)}`}
					</UnitPlaceCategoryName>
					<UnitName>{unit.name}</UnitName>
				</UnitInfoContainer>
			</Unit>
		);
	};

	const renderConsultUnit = (unit, idx) => {
		return (
			<Unit key={idx} onClick={(e) => onClickUnit(unit.id)}>
				{unit.thumbnail ? (
					<UnitThumbnail src={`${process.env.REACT_APP_API_URL}/storage/files/${unit.thumbnail?.filename}`} />
				) : (
					<EmptyUnitThumbnail />
				)}
				<UnitInfoContainer>
					<UnitPlaceCategoryName>
						{utils.getUnitCategoryName(unit, CategorySlug.CONSULT_PLACE, 1)}
					</UnitPlaceCategoryName>
					<UnitPlaceCategoryName>
						{`${utils.getUnitCategoryName(unit, CategorySlug.CONSULT_PLACE, 2)} ${utils.getUnitCategoryName(
							unit,
							CategorySlug.CONSULT_PLACE,
							3
						)}`}
					</UnitPlaceCategoryName>
					<UnitName>{unit.name}</UnitName>
				</UnitInfoContainer>
			</Unit>
		);
	};

	if (!initialized) return null;

	return (
		<Content>
			<section>
				<TitleContainer>
					<Title>예약신청</Title>
					<TitleTail src={isDesktop ? TitleTailImg : TitleTailMobileImg} />
				</TitleContainer>
			</section>
			<section>
				{/* 장비, 상담을 사용하는 경우에만 UnitTypeTab 표시 */}
				{(JSON.parse(process.env.REACT_APP_USE_TYPE_PRODUCT) ||
					JSON.parse(process.env.REACT_APP_USE_TYPE_CONSULT)) && (
					<UnitTypeTab
						tabs={getUnitTypeTabs()}
						value={unitType}
						onChange={(changedType) => setUnitType(changedType)}
					/>
				)}
				<StyledCategoryTab
					tabs={typeCategoryItemList.map((typeCategory) => {
						return {
							key: typeCategory.id,
							text: typeCategory.name,
						};
					})}
					value={typeCategoryItemId}
					onChange={(changedCategoryId) => {
						setTypeCategoryItemId(changedCategoryId);
					}}
				/>
			</section>
			<section>
				<Breadcrumb>
					<HomeIcon src={BreadcrumbHomeImg} onClick={onClickHome} />
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText>예약/신청</BreadcrumbText>
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText>예약신청</BreadcrumbText>
					<BreadcrumbSeparator src={BreadcrumbSeparatorImg} />
					<BreadcrumbText current>{`${UnitType.toString(unitType)}예약`}</BreadcrumbText>
				</Breadcrumb>
			</section>
			<section>
				<Row>
					<FilterColumn>
						<section>
							<FilterTitleContainer>
								<FilterTitle>{unitType === UnitType.CONSULT ? '분류' : '위치'}</FilterTitle>
							</FilterTitleContainer>
							{placeCategoryItemList?.map((placeCategoryItem, idx) =>
								renderPlaceCategoryItem(placeCategoryItem, idx)
							)}
						</section>
						<section className="mt-30px">
							<FilterTitleContainer>
								<FilterTitle>{`${UnitType.toString(unitType)}유형`}</FilterTitle>
							</FilterTitleContainer>
							{typeCategoryItemList
								?.find((typeCategory) => typeCategory.id === typeCategoryItemId)
								.children.map((typeCategoryItem, idx) => renderTypeCategoryItem(typeCategoryItem, idx))}
						</section>
						{/* UnitType에 따른 필터 */}
						{(unitType === UnitType.ROOM || unitType === UnitType.CONSULT) && (
							<section className="mt-30px">
								<FilterTitleContainer>
									<FilterTitle>{unitType === UnitType.ROOM ? '이용일자' : '상담일자'}</FilterTitle>
								</FilterTitleContainer>
								<SearchDateInput
									type="date"
									value={roomUsageStartDateFilter ?? ''}
									min={utils.convertDateToStr(new Date())}
									onChange={(e) => setRoomUsageStartDateFilter(e.currentTarget.value)}
								/>
							</section>
						)}
						{(unitType === UnitType.ROOM || unitType === UnitType.CONSULT) && (
							<section className="mt-30px">
								<FilterTitleContainer>
									<FilterTitle>시간대 검색</FilterTitle>
									<TimeFilterResetButton onClick={onClickResetTimeFilter}>
										<TimeFilterResetIcon src={TimeFilterResetImg} />
										<TimeFilterResetButtonText>초기화</TimeFilterResetButtonText>
									</TimeFilterResetButton>
								</FilterTitleContainer>
								<FilterSubTitle>시작시간</FilterSubTitle>
								<TimeSelect
									time={startTimeFilter}
									onChange={(value) => {
										if (value > endTimeFilter) {
											alert('시작시간은 종료시간보다 이후일 수 없습니다.');
											return false;
										}
										setStartTimeFilter(value);
										return true;
									}}
								></TimeSelect>
								<FilterSubTitle className="mt-10px">종료시간</FilterSubTitle>
								<TimeSelect
									time={endTimeFilter}
									onChange={(value) => {
										if (value < startTimeFilter) {
											alert('종료시간은 시작시간보다 이전일 수 없습니다.');
											return false;
										}
										setEndTimeFilter(value);
										return true;
									}}
								></TimeSelect>
							</section>
						)}
						{unitType === UnitType.PRODUCT && (
							<section className="mt-30px">
								<FilterTitleContainer>
									<FilterTitle>이용일자</FilterTitle>
								</FilterTitleContainer>
								<FilterSubTitle className="mt-10px mb-10px">시작일</FilterSubTitle>
								<SearchDateInput
									type="date"
									value={productUsageStartDateFilter ?? ''}
									min={utils.convertDateToStr(new Date())}
									onChange={(e) => {
										if (e.currentTarget.value > productUsageEndDateFilter) {
											alert('시작일은 종료일보다 이후일 수 없습니다.');
											return false;
										}
										setProductUsageStartDateFilter(e.currentTarget.value);
									}}
								/>
								<FilterSubTitle className="mt-10px mb-10px">종료일</FilterSubTitle>
								<SearchDateInput
									type="date"
									value={productUsageEndDateFilter ?? ''}
									min={utils.convertDateToStr(new Date())}
									onChange={(e) => {
										if (e.currentTarget.value < productUsageStartDateFilter) {
											alert('종료일은 시작일보다 이전일 수 없습니다.');
											return false;
										}
										setProductUsageEndDateFilter(e.currentTarget.value);
									}}
								/>
							</section>
						)}
					</FilterColumn>
					<UnitResultColumn>
						<ResultTitle>검색결과</ResultTitle>
						<ResultContainer>
							{unitList?.items.map((unit, idx) => {
								return renderUnit(unit, idx);
							})}
						</ResultContainer>
					</UnitResultColumn>
				</Row>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-bottom: 143px;
	background-color: white;

	@media only screen and (max-width: 767.98px) {
		margin: 20px 0 53px 0;
		overflow: hidden;
	}
`;

const TitleContainer = styled.div`
	margin-top: 65px;
	margin-bottom: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
		margin-bottom: 25px;
	}
`;

const Title = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 42px;
	line-height: 50px;
	text-align: center;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 24px;
		line-height: 29px;
	}
`;

const TitleTail = styled.img`
	margin-top: 13px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 5px;
	}
`;

const StyledCategoryTab = styled(CategoryTab)`
	margin-top: 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
	}
`;

const Breadcrumb = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;

	@media only screen and (max-width: 767.98px) {
		justify-content: center;
	}
`;

const HomeIcon = styled.img`
	cursor: pointer;
`;

const BreadcrumbSeparator = styled.img`
	margin: 0 10px;
`;

const BreadcrumbText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;

	${(props) =>
		props.current &&
		css`
			color: #22499d;
		`}

	@media only screen and (max-width: 767.98px) {
		font-size: 12px;
		line-height: 14px;
	}
`;

const Row = styled.div`
	display: flex;
	margin-top: 10px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		flex-direction: column;
	}
`;

const FilterColumn = styled.div`
	width: 280px;
	height: fit-content;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	padding: 30px 20px;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 20px);
		margin: 0 10px;
		padding: 15px;
	}
`;

const FilterTitleContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 15px;

	@media only screen and (max-width: 767.98px) {
		margin-bottom: 10px;
	}
`;

const FilterTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #023169;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const TimeFilterResetIcon = styled.img`
	margin-right: 4px;
`;

const TimeFilterResetButtonText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #555555;
`;

const TimeFilterResetButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 61px;
	height: 27px;
	margin-left: 10px;

	border-radius: 3px;
	border: 1px solid #c2c2c2;
	cursor: pointer;

	&:hover {
		border: 1px solid #2b62ab;

		& > ${TimeFilterResetButtonText} {
			color: #2b62ab;
		}
	}
`;

const FilterSubTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #333333;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const CategoryItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 240px;
	height: 41px;
	padding: 0 11px;
	border: 1px solid #dddddd;
	border-radius: 5px;
	cursor: pointer;

	&:hover {
		border: 1px solid #2b62ab;
	}

	& + & {
		margin-top: 5px;
	}

	${(props) =>
		props.selected &&
		css`
			background-color: #5b606e;
			border: 1px solid #5b606e;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 40px;

		&:hover {
			border: 1px solid #5b606e;
		}
	}
`;

const PlaceCategoryItemContainer = styled.div`
	& + & {
		margin-top: 5px;
	}
`;

const PlaceCategoryItem = styled(CategoryItem)`
	${(props) =>
		props.selected &&
		css`
			background-color: #5b606e;
			border-radius: ${(props) => (props.noChildren ? '4px' : '4px 4px 0 0')};
		`}
`;

const CategoryItemName = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	word-break: break-all;

	${(props) =>
		props.selected &&
		css`
			color: #ffffff;
		`}

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const CategoryChildrenTree = styled.div`
	width: 240px;
	padding: 12px 10px;
	border: 1px solid #666666;
	border-top: none;
	border-radius: 0 0 4px 4px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const CategoryChild = styled.div`
	& + & {
		margin-top: 5px;
	}
`;

const FilterCheckbox = styled.input`
	width: 15px;
	height: 15px;
	border: 1px solid #c5c5c5;
	margin: 0 11px 0 17px;
	cursor: pointer;
`;

const StyledIndeterminateCheckBoxOutlinedIcon = styled(IndeterminateCheckBoxOutlinedIcon)`
	font-size: 20px !important;
	margin: 0 9px 0 14px;
	color: #ffffff;
	cursor: pointer;
`;

const CategoryChildDepth2 = styled.div`
	display: flex;
	align-items: center;
	width: 220px;
	height: 35px;
	border-radius: 4px;
	background-color: #e5e8f1;

	${(props) =>
		props.selected &&
		css`
			background-color: #0a2d8f;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 40px;
	}
`;

const CategoryChildDepth2Label = styled.label`
	width: 150px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 17px;
	color: #333333;
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	word-break: break-all;

	${(props) =>
		props.selected &&
		css`
			color: #ffffff;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		font-size: 14px;
		line-height: 17px;
	}
`;

const CategoryChildDepth3 = styled.div`
	display: flex;
	align-items: center;
	width: 180px;
	height: 25px;
	padding-left: 20px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 30px;
	}
`;

const CategoryChildDepth3Label = styled.label`
	width: 150px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #666666;
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		font-size: 14px;
		line-height: 17px;
	}
`;

const SearchDateInput = styled.input`
	width: 240px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;
	border-radius: 4px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 16px;
	color: #666666;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		font-size: 14px;
		line-height: 17px;
	}
`;

const UnitResultColumn = styled.div`
	width: 940px;
	margin-left: 32px;
	background-color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-top: 20px;
		margin-left: 0;
		padding: 0 5px;
	}
`;

const ResultTitle = styled.div`
	margin-top: 20px;
	margin-left: 6px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 36px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		margin-left: 5px;
		font-size: 22px;
		line-height: 26px;
	}
`;

const ResultContainer = styled.div`
	margin-top: 17px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		justify-content: space-around;
	}
`;

const Unit = styled.div`
	width: 220px;
	height: 250px;
	cursor: pointer;
	margin: 15px 6px;

	@media only screen and (max-width: 767.98px) {
		width: 145px;
		height: auto;
		margin: 5px;
	}
`;

const UnitThumbnail = styled.img`
	width: 220px;
	height: 140px;
	object-fit: contain;
	border-radius: 5px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 92px;
	}
`;

const EmptyUnitThumbnail = styled.div`
	width: 220px;
	height: 140px;
	background-color: #dddddd;
	border-radius: 5px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 92px;
	}
`;

const UnitInfoContainer = styled.div`
	width: 100%;
	padding: 15px 10px 30px 10px;

	@media only screen and (max-width: 767.98px) {
		padding: 9px 6px 0 6px;
	}
`;

const UnitPlaceCategoryName = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	word-break: break-all;

	& + & {
		margin-top: 5px;
	}

	@media only screen and (max-width: 767.98px) {
		font-size: 13px;
		line-height: 16px;

		& + & {
			margin-top: 0px;
		}
	}
`;

const UnitName = styled.div`
	margin-top: 5px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
	word-break: break-all;
	height: 45px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	@media only screen and (max-width: 767.98px) {
		margin-top: 6px;
		font-size: 16px;
		line-height: 19px;
		height: 40px;
	}
`;

const EnableProductItems = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #22499d;
`;

export default Booking;
