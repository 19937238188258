export default {
	PARTICIPATION_READY: 'participation_ready', // 상담대기
	PARTICIPATION_COMPLETED: 'participation_completed', // 상담 완료
	PARTICIPATION_ABSENT: 'participation_absent', // 불참
	PARTICIPATION_PENDING: 'participation_pending', // 보류

	toString: function (value) {
		switch (value) {
			case this.PARTICIPATION_READY:
				return '상담대기';
			case this.PARTICIPATION_COMPLETED:
				return '상담완료';
			case this.PARTICIPATION_ABSENT:
				return '불참';
			case this.PARTICIPATION_PENDING:
				return '보류';
			default:
				return '-';
		}
	},
};
