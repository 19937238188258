import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import useFilterHandler from '../../hooks/useFilterHandler';
import CategorySlug from '../../constants/CategorySlug';
import ApprovalStatus from '../../constants/ApprovalStatus';
import RentalStatus from '../../constants/RentalStatus';
import ParticipationStatus from '../../constants/ParticipationStatus';
import UnitType from '../../constants/UnitType';

import * as api from '../../apis';
import * as utils from '../../utils';

const BookingItemModal = ({ isAdmin, unitType, booking, onClose }) => {
	const [approvalStatus, setApprovalStatus] = useState(booking?.approval_status);
	const [rentalStatus, setRentalStatus] = useState(booking?.rental_status);
	const [participationStatus, setParticipationStatus] = useState(booking?.participation_status);
	const [requestTitle, setRequestTitle] = useState(booking?.operator_request?.title);
	const [requestDescription, setRequestDescription] = useState(booking?.operator_request?.description);
	const [answerDescription, setAnswerDescription] = useState(booking?.operator_request?.answer);
	const { categoryFilter } = useFilterHandler({
		onFetchCompleted: () => {
			setInitialized(true);
		},
	});
	const [initialized, setInitialized] = useState(false);

	const onClickConfirm = async () => {
		try {
			if (isAdmin) {
				if (booking?.approval_status !== approvalStatus) {
					const params = {
						booking_ids: [booking?.id],
						approval_status: approvalStatus,
					};

					await api.updateApprovalStatus(params);
				}

				if (unitType === UnitType.PRODUCT && booking?.rental_status !== rentalStatus) {
					const params = {
						booking_ids: [booking?.id],
						rental_status: rentalStatus,
					};

					await api.updateRentalStatus(params);
				}

				// 상담인 경우 참여 여부 변경 처리
				if (unitType === UnitType.CONSULT && booking?.participation_status !== participationStatus) {
					const params = {
						booking_ids: [booking?.id],
						participation_status: participationStatus,
					};

					await api.updateParticipationStatus(params);
				}

				if (requestTitle && requestDescription) {
					const params = {
						title: requestTitle,
						description: requestDescription,
					};

					await api.createBookingOperatorRequest(booking.id, params);
				}
			}

			onClose(true);
		} catch (err) {
			console.error(err);
		}
	};

	const renderBookingRoomSection = () => {
		return (
			<>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 1)}</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PLACE, 1)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 2)}</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PLACE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 3)}</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PLACE, 3)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.TYPE, 2)}</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.TYPE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>시설명</InfoTitle>
						<InfoDescription>{booking?.unit?.name ?? '-'}</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>호실</InfoTitle>
						<InfoDescription>{booking?.room_item?.name ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>이용기간</InfoTitle>
						<InfoDescription>{booking?.booking_date ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>사용 인원</InfoTitle>
						<InfoDescription>{`${booking?.usage_count ?? '-'}명`}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>촬영내용</InfoTitle>
						<InfoDescription className="w-100">{booking?.purpose_description ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>특이사항</InfoTitle>
						<InfoDescription className="w-100">{booking?.extra_description ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>교안 파일</InfoTitle>
						{booking?.attachments?.length === 0 && <InfoDescription>-</InfoDescription>}
						<AttachmentContainer>
							{booking?.attachments?.map((file, idx) => {
								return (
									<InfoDescription key={idx}>
										<InfoDescriptionText>
											<a
												href={`${process.env.REACT_APP_API_URL}/storage/files/${file.filename}`}
												download={file.org_filename ?? '-'}
											>
												{file.org_filename ?? '-'}
											</a>
										</InfoDescriptionText>
									</InfoDescription>
								);
							})}
						</AttachmentContainer>
					</InfoColumn>
				</InfoRow>
			</>
		);
	};

	const renderBookingProductSection = () => {
		return (
			<>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_PLACE, 1)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PRODUCT_PLACE, 1)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_PLACE, 2)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PRODUCT_PLACE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_PLACE, 3)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PRODUCT_PLACE, 3)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PRODUCT_TYPE, 2)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.PRODUCT_TYPE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>장비명</InfoTitle>
						<InfoDescription>{booking?.unit?.name ?? '-'}</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>장비번호</InfoTitle>
						<InfoDescription>
							{booking?.product_items?.map((productItem) => productItem.name).join(',') ?? '-'}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>이용기간</InfoTitle>
						<InfoDescription>{booking?.booking_date ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>예약수량</InfoTitle>
						<InfoDescription>{`${booking?.usage_count ?? '-'}개`}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>이용목적</InfoTitle>
						<InfoDescription className="w-100">{booking?.purpose_description ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>특이사항</InfoTitle>
						<InfoDescription className="w-100">{booking?.extra_description ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
			</>
		);
	};

	const renderBookingConsultSection = () => {
		return (
			<>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_PLACE, 1)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.CONSULT_PLACE, 1)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_PLACE, 2)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.CONSULT_PLACE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_PLACE, 3)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.CONSULT_PLACE, 3)}
						</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>
							{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.CONSULT_TYPE, 2)}
						</InfoTitle>
						<InfoDescription>
							{utils.getUnitCategoryName(booking?.unit, CategorySlug.CONSULT_TYPE, 2)}
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn>
						<InfoTitle>상담명</InfoTitle>
						<InfoDescription>{booking?.unit?.name ?? '-'}</InfoDescription>
					</InfoColumn>
					<InfoColumn>
						<InfoTitle>상담자</InfoTitle>
						<InfoDescription>{booking?.room_item?.name ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>상담일정</InfoTitle>
						<InfoDescription>{booking?.booking_date ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>참여인원</InfoTitle>
						<InfoDescription>{`${booking?.usage_count ?? '-'}명`}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>상담내용</InfoTitle>
						<InfoDescription className="w-100">{booking?.purpose_description ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>특이사항</InfoTitle>
						<InfoDescription className="w-100">{booking?.extra_description ?? '-'}</InfoDescription>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>첨부파일</InfoTitle>
						{booking?.attachments?.length === 0 && <InfoDescription>-</InfoDescription>}
						<AttachmentContainer>
							{booking?.attachments?.map((file, idx) => {
								return (
									<InfoDescription key={idx}>
										<InfoDescriptionText>
											<a
												href={`${process.env.REACT_APP_API_URL}/storage/files/${file.filename}`}
												download={file.org_filename ?? '-'}
											>
												{file.org_filename ?? '-'}
											</a>
										</InfoDescriptionText>
									</InfoDescription>
								);
							})}
						</AttachmentContainer>
					</InfoColumn>
				</InfoRow>
			</>
		);
	};

	const renderAdminSection = () => {
		return (
			<InfoContainer>
				<SectionTitle>관리 내역</SectionTitle>
				<InfoRow first>
					<InfoColumn className="w-100">
						<InfoTitle>예약 상태</InfoTitle>
						<InfoDescription>
							<StatusChangeSelect
								value={approvalStatus}
								onChange={(e) => setApprovalStatus(e.currentTarget.value)}
							>
								<option value={ApprovalStatus.APPROVED}>
									{ApprovalStatus.toString(ApprovalStatus.APPROVED)}
								</option>
								<option value={ApprovalStatus.REJECTED}>
									{ApprovalStatus.toString(ApprovalStatus.REJECTED)}
								</option>
								<option value={ApprovalStatus.CANCELED}>
									{ApprovalStatus.toString(ApprovalStatus.CANCELED)}
								</option>
								<option value={ApprovalStatus.PENDING}>
									{ApprovalStatus.toString(ApprovalStatus.PENDING)}
								</option>
							</StatusChangeSelect>
						</InfoDescription>
					</InfoColumn>
				</InfoRow>
				{unitType === UnitType.PRODUCT && (
					<InfoRow>
						<InfoColumn className="w-100">
							<InfoTitle>수령/반납 상태</InfoTitle>
							<InfoDescription>
								<StatusChangeSelect
									value={rentalStatus}
									onChange={(e) => setRentalStatus(e.currentTarget.value)}
								>
									<option value={RentalStatus.RENTAL_READY}>
										{RentalStatus.toString(RentalStatus.RENTAL_READY)}
									</option>
									<option value={RentalStatus.RENTAL_COMPLETE}>
										{RentalStatus.toString(RentalStatus.RENTAL_COMPLETE)}
									</option>
									<option value={RentalStatus.RENTAL_DELAY}>
										{RentalStatus.toString(RentalStatus.RENTAL_DELAY)}
									</option>
									<option value={RentalStatus.RETURN_COMPLETE}>
										{RentalStatus.toString(RentalStatus.RETURN_COMPLETE)}
									</option>
									<option value={RentalStatus.RETURN_DELAY}>
										{RentalStatus.toString(RentalStatus.RETURN_DELAY)}
									</option>
								</StatusChangeSelect>
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
				)}
				{unitType === UnitType.CONSULT && (
					<InfoRow>
						<InfoColumn className="w-100">
							<InfoTitle>참여 여부</InfoTitle>
							<InfoDescription>
								<StatusChangeSelect
									value={participationStatus ?? ParticipationStatus.PARTICIPATION_READY}
									onChange={(e) => setParticipationStatus(e.currentTarget.value)}
								>
									<option value={ParticipationStatus.PARTICIPATION_READY}>
										{ParticipationStatus.toString(ParticipationStatus.PARTICIPATION_READY)}
									</option>
									<option value={ParticipationStatus.PARTICIPATION_COMPLETED}>
										{ParticipationStatus.toString(ParticipationStatus.PARTICIPATION_COMPLETED)}
									</option>
									<option value={ParticipationStatus.PARTICIPATION_ABSENT}>
										{ParticipationStatus.toString(ParticipationStatus.PARTICIPATION_ABSENT)}
									</option>
									<option value={ParticipationStatus.PARTICIPATION_PENDING}>
										{ParticipationStatus.toString(ParticipationStatus.PARTICIPATION_PENDING)}
									</option>
								</StatusChangeSelect>
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
				)}
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>관리자 요청사항</InfoTitle>
						<RequestContainer>
							<RequestTitleInput
								value={requestTitle ?? ''}
								placeholder="요청사항 제목을 작성해주세요"
								onChange={(e) => setRequestTitle(e.currentTarget.value)}
							/>
							<RequestDescriptionInput
								value={requestDescription}
								placeholder="요청사항 내용을 작성해주세요"
								onChange={(e) => setRequestDescription(e.currentTarget.value)}
							/>
						</RequestContainer>
					</InfoColumn>
				</InfoRow>
				<InfoRow>
					<InfoColumn className="w-100">
						<InfoTitle>예약자 답변</InfoTitle>
						<AnswerContainer>
							<AnswerText>{answerDescription ?? '답변이 존재하지 않습니다.'}</AnswerText>
						</AnswerContainer>
					</InfoColumn>
				</InfoRow>
			</InfoContainer>
		);
	};

	if (!initialized) return null;

	return (
		<ModalContainer>
			<Content>
				<ModalTopContainer>
					<ModalTitle>신청 내역 확인</ModalTitle>
					<CloseButton onClick={() => onClose(false)}>x</CloseButton>
				</ModalTopContainer>
				<InfoContainer>
					<InfoRow first>
						<InfoColumn>
							<InfoTitle>예약번호</InfoTitle>
							<InfoDescription>{`#${booking.id ?? '0'}`}</InfoDescription>
						</InfoColumn>
						<InfoColumn>
							<InfoTitle>신청일</InfoTitle>
							<InfoDescription>
								{booking.created_at ? new Date(booking.created_at).toLocaleString() : '-'}
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
					<InfoRow>
						<InfoColumn>
							<InfoTitle>예약자</InfoTitle>
							<InfoDescription>{booking?.user?.name ?? '-'}</InfoDescription>
						</InfoColumn>
						<InfoColumn>
							<InfoTitle>학번/교직원 번호</InfoTitle>
							<InfoDescription>{booking?.user?.user_login ?? '-'}</InfoDescription>
						</InfoColumn>
					</InfoRow>
					<InfoRow>
						<InfoColumn>
							<InfoTitle>이메일</InfoTitle>
							<InfoDescription>{booking?.user?.email ?? '-'}</InfoDescription>
						</InfoColumn>
						<InfoColumn>
							<InfoTitle>연락처</InfoTitle>
							<InfoDescription>
								{booking?.contact ? booking?.contact : booking?.user?.phone ?? '-'}
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
					{unitType === UnitType.ROOM && renderBookingRoomSection()}
					{unitType === UnitType.PRODUCT && renderBookingProductSection()}
					{unitType === UnitType.CONSULT && renderBookingConsultSection()}
				</InfoContainer>
				{isAdmin && renderAdminSection()}
				<Footer>
					<CancelButton onClick={() => onClose(false)}>취소</CancelButton>
					<OkButton onClick={onClickConfirm}>확인</OkButton>
				</Footer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		padding: 15px;
	}
`;

const Content = styled.div`
	position: relative;
	width: 720px;
	max-height: 80%;
	overflow-y: scroll;

	background-color: white;

	background: #ffffff;
	border: 1px solid #777777;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 15px 10px;
	}
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	border-bottom: 1px solid #cccccc;

	@media only screen and (max-width: 767.98px) {
		padding: 0 0 10px 0;
	}
`;

const ModalTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 24px;
		line-height: 29px;
	}
`;

const CloseButton = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: center;

	cursor: pointer;

	color: #000000;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const InfoContainer = styled.div`
	padding: 20px 30px 0px 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		padding: 0;
	}
`;

const InfoRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;

	${(props) =>
		props.first &&
		css`
			border-top: 2px solid #333333;
		`}

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		min-height: auto;
		flex-direction: column;
		border-bottom: none;
	}
`;

const InfoColumn = styled.div`
	display: flex;
	width: 50%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		flex-direction: column;
		border-bottom: 1px solid #dddddd;
		padding: 10px;
	}
`;

const InfoTitle = styled.span`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 18px;

	width: 120px;
	min-width: 120px;

	background-color: #f7f7f7;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding-left: 0;
		background-color: #ffffff;
	}
`;

const InfoDescription = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	padding: 5px 15px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-top: 5px;
		padding: 0;
	}
`;

const InfoDescriptionText = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	width: 100%;
	height: 100%;
	padding: 8px;
	border: none;
	border-radius: 4px;
	background-color: #f7f7f7;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const AttachmentContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const SectionTitle = styled.div`
	margin-top: 20px;
	margin-bottom: 20px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0;
`;

const CancelButton = styled.div`
	width: 125px;
	height: 50px;
	border: 1px solid #22499d;
	border-radius: 5px;
	background-color: #ffffff;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #22499d;

	&:hover {
		background-color: #0c438d;
		color: #ffffff;
	}
`;

const OkButton = styled.div`
	width: 125px;
	height: 50px;
	margin-left: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	background-color: #22499d;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #ffffff;

	&:hover {
		background-color: #0c438d;
	}
`;

const StatusChangeSelect = styled.select`
	width: 80px;
	height: 28px;
	padding-left: 8px;

	border-radius: 4px;
	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 15px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #666666;
`;

const RequestContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 175px;
	margin: 5px 15px;
	padding: 5px 0;
`;

const RequestTitleInput = styled.input`
	width: 100%;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const RequestDescriptionInput = styled.textarea`
	width: 100%;
	height: 135px;

	margin-top: 8px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

const AnswerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100px;
	background: #f7f7f7;
	border-radius: 4px;
	margin: 5px 15px;
	padding: 10px;
`;

const AnswerText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #666666;
`;

export default BookingItemModal;
