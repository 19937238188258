import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';

import CategorySlug from '../../../constants/CategorySlug';
import CategoryItemModal from '../../../components/modal/CategoryItemModal';

import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import { usePrompt } from '../../../hooks/blocker';

import * as api from '../../../apis';
import UnitType from '../../../constants/UnitType';

const ItemCategorySetting = ({ unitType }) => {
	const [categories, setCategories] = useState([]);
	const [usedCategoryIds, setUsedCategoryIds] = useState([]);
	const [categoryItemModalData, setCategoryItemModalData] = useState({
		show: false,
		category_id: null,
		parent_item_id: null,
		category_item_id: null,
		category_item_name: null,
	});

	useEffect(() => {
		refreshCategories();
	}, [unitType]);

	const refreshCategories = () => {
		let filterParams = [`unit_type=${unitType}`];
		api.getCategories(filterParams)
			.then((res) => {
				if (res && res.data) {
					setCategories(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});

		api.getUsedCategoryIds()
			.then((res) => {
				if (res && res.data) {
					setUsedCategoryIds(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getCategoryModified = () => {
		if (!categories) return false;
		return categories.some((category) => category.modified === true);
	};

	usePrompt('저장하지 않은 분류가 있습니다.\n페이지를 벗어나시겠습니까?', getCategoryModified());

	const getAllCategoryItemsId = (children, result) => {
		return children.forEach((child, _) => {
			result.push(`${child.id}`);
			if (child.children.length !== 0) {
				getAllCategoryItemsId(child.children, result);
			}
		});
	};

	const onUpdateCategory = (categoryId) => {
		let category = categories.find((category) => category.id === categoryId);
		category.items = reorderCategoryItems(category.items);
		api.updateCategory(categoryId, category)
			.then((res) => {
				if (res && (res.data || res.status === 204)) {
					alert('분류를 저장하였습니다.');
					refreshCategories();
					return;
				}
				alert('분류 저장에 실패하였습니다.');
			})
			.catch((err) => {
				alert('분류 저장에 실패하였습니다.');
				console.error(err);
			});
	};

	const reorderCategoryItems = (categoryItems) => {
		return categoryItems.map((categoryItem, index) => {
			return {
				...categoryItem,
				id: `${categoryItem.id}`.includes('new') ? null : categoryItem.id,
				depth: categoryItem.depth,
				order: index + 1,
				children: reorderCategoryItems(categoryItem.children),
			};
		});
	};

	const hideCategoryItemModal = () => {
		setCategoryItemModalData({
			show: false,
			category_id: null,
			parent_item_id: null,
			category_item_id: null,
			category_item_name: null,
		});
	};

	const onShowAddRootCategoryItemModal = (categoryId) => {
		setCategoryItemModalData({
			show: true,
			category_id: categoryId,
			parent_item_id: null,
			category_item_id: null,
			category_item_name: null,
		});
	};

	const onShowAddCategoryItemModal = (parentCategoryItemId) => {
		setCategoryItemModalData({
			show: true,
			category_id: null,
			parent_item_id: parentCategoryItemId,
			category_item_id: null,
			category_item_name: null,
		});
	};

	const onShowEditCategoryItemModal = (categoryItemId, categoryName) => {
		setCategoryItemModalData({
			show: true,
			category_id: null,
			parent_item_id: null,
			category_item_id: categoryItemId,
			category_item_name: categoryName,
		});
	};

	const onAddRootCategoryItem = (categoryName) => {
		let updatedCategories = categories.map((category) => {
			let categoryItems = category.items;
			if (categoryItemModalData.category_id === category.id) {
				categoryItems.push({
					id: `new-${Math.floor(Math.random() * 100)}`,
					parent_item_id: null,
					name: categoryName,
					depth: 1,
					order: categoryItems.length + 1,
					children: [],
				});
			}
			return { ...category, modified: true, items: categoryItems };
		});
		setCategories(updatedCategories);
		hideCategoryItemModal();
	};

	const onAddCategoryItem = (categoryName) => {
		let parentCategoryItemId = categoryItemModalData.parent_item_id;
		let updatedCategories = categories.map((category) => {
			return {
				...category,
				modified: true,
				items: addCategoryItem(1, parentCategoryItemId, category.items, categoryName),
			};
		});
		setCategories(updatedCategories);
		hideCategoryItemModal();
	};

	const addCategoryItem = (depth, parentCategoryItemId, categoryItems, categoryName) => {
		return categoryItems.map((categoryItem) => {
			if (categoryItem.id === parentCategoryItemId) {
				categoryItem.children.push({
					id: `new-${Math.floor(Math.random() * 100)}`,
					parent_item_id: parentCategoryItemId,
					name: categoryName,
					depth: depth + 1,
					order: categoryItem.children.length + 1,
					children: [],
				});
			}
			return {
				...categoryItem,
				children: addCategoryItem(depth + 1, parentCategoryItemId, categoryItem.children, categoryName),
			};
		});
	};

	const onEditCategoryItem = (categoryItemId, categoryName) => {
		let updatedCategories = categories.map((category) => {
			return {
				...category,
				modified: true,
				items: editCategoryItem(category.items, categoryItemId, categoryName),
			};
		});
		setCategories(updatedCategories);
		hideCategoryItemModal();
	};

	const editCategoryItem = (categoryItems, categoryItemId, categoryName) => {
		return categoryItems.map((categoryItem) => {
			if (categoryItem.id === categoryItemId) {
				return {
					...categoryItem,
					name: categoryName,
				};
			}
			return {
				...categoryItem,
				children: editCategoryItem(categoryItem.children, categoryItemId, categoryName),
			};
		});
	};

	const onRemoveCategoryItem = (categoryItemId) => {
		if (usedCategoryIds.includes(categoryItemId)) {
			if (!window.confirm('해당 분류를 포함하는 예약 항목이 있습니다.\n그래도 삭제하시겠습니까?')) {
				return;
			}
		} else {
			if (!window.confirm('정말 삭제하시겠습니까?')) {
				return;
			}
		}
		let updatedCategories = categories.map((category) => {
			return { ...category, modified: true, items: removeCategoryItem(categoryItemId, category.items) };
		});
		setCategories(updatedCategories);
	};

	const removeCategoryItem = (categoryItemId, categoryItems) => {
		return categoryItems
			.filter((categoryItem) => {
				return categoryItem.id != categoryItemId;
			})
			.map((categoryItem) => {
				return { ...categoryItem, children: removeCategoryItem(categoryItemId, categoryItem.children) };
			});
	};

	const renderItemSectionTitle = () => {
		switch (unitType) {
			case UnitType.ROOM:
				return (
					<>
						<SectionTitleContainer>
							<SectionTitle>예약 유형</SectionTitle>
							<SectionTitleSeparator />
							<SectionSubTitle>시설</SectionSubTitle>
						</SectionTitleContainer>
					</>
				);
			case UnitType.PRODUCT:
				return (
					<>
						<SectionTitleContainer>
							<SectionTitle>예약 단위</SectionTitle>
							<SectionTitleSeparator />
							<SectionSubTitle>장비</SectionSubTitle>
						</SectionTitleContainer>
					</>
				);
			case UnitType.CONSULT:
				return (
					<>
						<SectionTitleContainer>
							<SectionTitle>예약 유형</SectionTitle>
							<SectionTitleSeparator />
							<SectionSubTitle>상담</SectionSubTitle>
						</SectionTitleContainer>
					</>
				);
		}
		return null;
	};

	const renderCategory = (category, maxDepth) => {
		if (!category) return null;

		let defaultExpanded = [];
		getAllCategoryItemsId(category.items, defaultExpanded);

		return (
			<>
				<CategoryNameContainer>
					<CategoryName>{category.name}</CategoryName>
					<CategoryUpdateButtonContainer>
						<CategoryAddButton onClick={() => onShowAddRootCategoryItemModal(category.id)}>
							추가
						</CategoryAddButton>
						<CategorySaveButton onClick={() => onUpdateCategory(category.id)}>저장</CategorySaveButton>
					</CategoryUpdateButtonContainer>
				</CategoryNameContainer>
				<section className="mt-10px">
					<TreeView
						defaultCollapseIcon={<StyledArrowDropDownIcon />}
						defaultExpandIcon={<StyledArrowRightIcon />}
						sx={{ maxWidth: 350 }}
						defaultExpanded={defaultExpanded}
					>
						{renderCategoryChildren(category.items, 1, maxDepth)}
					</TreeView>
				</section>
			</>
		);
	};

	const renderCategoryChildren = (children, depth, maxDepth) => {
		return children.map((child, _) => {
			return (
				<StyledTreeItem
					key={_}
					nodeId={`${child.id}`}
					label={renderCategoryItem(child.id, child.name, depth !== maxDepth)}
				>
					{child.children.length !== 0 && renderCategoryChildren(child.children, depth + 1, maxDepth)}
				</StyledTreeItem>
			);
		});
	};

	const renderCategoryItem = (categoryItemId, name, enableAdd) => {
		return (
			<CategoryItemContainer className="category-item-container" title={name}>
				<CategoryItemName>{name}</CategoryItemName>
				<CategoryButtonContainer>
					<StyledEditIcon
						onClick={(e) => {
							e.stopPropagation();
							onShowEditCategoryItemModal(categoryItemId, name);
						}}
					/>
					{enableAdd && (
						<StyledAddIcon
							onClick={(e) => {
								e.stopPropagation();
								onShowAddCategoryItemModal(categoryItemId);
							}}
						/>
					)}
					<StyledRemoveIcon
						onClick={(e) => {
							e.stopPropagation();
							onRemoveCategoryItem(categoryItemId);
						}}
					/>
				</CategoryButtonContainer>
			</CategoryItemContainer>
		);
	};

	const getTypeCategorySlug = () => {
		switch (unitType) {
			case UnitType.ROOM:
				return CategorySlug.TYPE;
			case UnitType.PRODUCT:
				return CategorySlug.PRODUCT_TYPE;
			case UnitType.CONSULT:
				return CategorySlug.CONSULT_TYPE;
		}

		return CategorySlug.TYPE;
	};

	const getPlaceCategorySlug = () => {
		switch (unitType) {
			case UnitType.ROOM:
				return CategorySlug.PLACE;
			case UnitType.PRODUCT:
				return CategorySlug.PRODUCT_PLACE;
			case UnitType.CONSULT:
				return CategorySlug.CONSULT_PLACE;
		}

		return CategorySlug.PLACE;
	};

	return (
		<Content>
			{renderItemSectionTitle()}
			<CategoryContainerRow>
				<CategoryContainer>
					{renderCategory(categories.filter((category) => category.slug == getTypeCategorySlug())[0], 2)}
				</CategoryContainer>
				<CategoryContainer>
					{renderCategory(categories.filter((category) => category.slug == getPlaceCategorySlug())[0], 3)}
				</CategoryContainer>
			</CategoryContainerRow>
			{categoryItemModalData.show && (
				<CategoryItemModal
					defaultName={categoryItemModalData.category_item_name}
					onConfirm={(name) => {
						if (categoryItemModalData.category_id) {
							onAddRootCategoryItem(name);
							return;
						}
						if (categoryItemModalData.category_item_id) {
							onEditCategoryItem(categoryItemModalData.category_item_id, name);
							return;
						}
						onAddCategoryItem(name);
					}}
					onClose={() => hideCategoryItemModal()}
				/>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px;
	background-color: white;
`;

const SectionTitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const SectionTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const SectionTitleSeparator = styled.div`
	margin: 0 12px;
	width: 1px;
	height: 17.5px;
	background-color: #dddddd;
`;

const SectionSubTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #808080;
`;

const CategoryContainerRow = styled.div`
	display: flex;
`;

const CategoryContainer = styled.div`
	margin-top: 20px;
	width: 50%;

	& + & {
		margin-left: 44px;
	}
`;

const CategoryNameContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 54px;
	border-top: 1px solid #aeaeae;
	border-bottom: 1px solid #aeaeae;
`;

const CategoryName = styled.div`
	padding-left: 11px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
`;

const CategoryUpdateButtonContainer = styled.div`
	margin-right: 10px;
	display: flex;
	align-items: center;
`;

const CategoryAddButton = styled.div`
	width: 50px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const CategorySaveButton = styled.div`
	width: 50px;
	height: 30px;
	margin-left: 10px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #454655;
	border-radius: 5px;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	&:hover {
		background-color: #1d1e31;
	}
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
	font-size: 24px !important;
	color: #000000;
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
	font-size: 24px !important;
	color: #000000;
`;

const StyledTreeItem = muiStyled((props) => <TreeItem {...props} />)(({ theme }) => ({
	[`& .${treeItemClasses.content}`]: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:hover .category-item-container': {
			backgroundColor: '#f5f5f5;',
		},
		'&.Mui-selected:hover': {
			backgroundColor: 'transparent',
		},
		'&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: 'transparent',
		},
		'&.Mui-selected .category-item-container': {
			backgroundColor: '#acc5e3',
			color: '#ffffff',
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 10,
		paddingLeft: 5,
	},
}));

const CategoryItemContainer = styled.div`
	display: flex;
	align-items: center;
	height: 35px;
	margin: 2px 0;
	padding: 0 8px;

	border-radius: 4px;
	border: 1px solid #dddddd;
	box-sizing: border-box;
	color: #666666;
`;

const CategoryItemName = styled.div`
	width: 170px;
	height: 35px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;

	text-overflow: ellipsis;
	overflow: hidden;

	line-height: 35px;
	white-space: nowrap;
	word-break: break-all;
`;

const CategoryButtonContainer = styled.div`
	margin-left: auto;
`;

const StyledAddIcon = styled(AddCircleOutlineRoundedIcon)`
	font-size: 24px !important;
	margin-left: 4px;
`;

const StyledRemoveIcon = styled(RemoveCircleOutlineRoundedIcon)`
	font-size: 24px !important;
	margin-left: 4px;
`;

const StyledEditIcon = styled(ModeEditOutlineIcon)`
	font-size: 20px !important;
`;

export default ItemCategorySetting;
