import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';
import { UserContext } from '../contexts/UserContext';
import Day from '../constants/Day';
import CategorySlug from '../constants/CategorySlug';
import AdditionalMediaType from '../constants/AdditionalMediaType';
import UnitType from '../constants/UnitType';
import TabArrowDownActiveIconImage from '../assets/images/screens/Booking/tab_arrow_down_active.svg';
import TabArrowDownNormalIconImage from '../assets/images/screens/Booking/tab_arrow_down_normal.svg';
import CheckIconImage from '../assets/images/screens/Booking/check.svg';

import * as api from '../apis';
import * as utils from '../utils';

const BookingProductSchedule = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { isDesktop } = useSizeDetector();
	const { user } = useContext(UserContext);

	const [tabIndex, setTabIndex] = useState(0);
	const [unitData, setUnitData] = useState(null);
	const [usageStartDate, setUsageStartDate] = useState(null);
	const [usageEndDate, setUsageEndDate] = useState(null);
	const [systemBlockScheduleList, setSystemBlockDateList] = useState(null);

	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		Promise.all([getUnitFromApi(), getSystemSchedulesFromApi()])
			.then((values) => {
				setUnitData(values[0]);
				setSystemBlockDateList(values[1]);
				setInitialized(true);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const getUnitFromApi = () => {
		return new Promise((resolve, reject) => {
			api.getUnit(id)
				.then((res) => {
					if (res && res.data) {
						resolve(res.data);
					}
				})
				.catch((err) => {
					console.error(err);
					reject();
				});
		});
	};

	const getSystemSchedulesFromApi = () => {
		return new Promise((resolve, reject) => {
			api.getSystemSchedules()
				.then((res) => {
					if (res && res.data) {
						let systemBlockScheduleList = res.data.filter((schedule) => schedule.allow_status === false);
						resolve(systemBlockScheduleList);
					}
				})
				.catch((err) => {
					console.error(err);
					reject();
				});
		});
	};

	useEffect(() => {
		if (!initialized) return;

		// 데스크톱 메뉴 스크롤 감지
		const option = {
			root: null, //viewport
			rootMargin: '0px',
			threshold: 0.45, // 45%가 viewport에 들어와 있어야 callback 실행
		};
		const callback = (entries, observer) => {
			entries.forEach((entry) => {
				let targetElemId = null;
				if (entry.isIntersecting) {
					targetElemId = entry.target.id;
				}
				if (targetElemId) {
					setTabIndex(parseInt(targetElemId.replace('section', '')));
				}
			});
		};
		const observer = new IntersectionObserver(callback, option);
		const sections = document.getElementsByClassName('tab-section');
		for (let section of sections) {
			observer.observe(section);
		}
	}, [initialized]);

	const convertMinuteToDayStr = (minute) => {
		let day = parseInt(minute / 60 / 24);
		return `${day}일`;
	};

	const getUnitCategoryFullName = () => {
		return `${utils.getUnitCategoryName(unitData, CategorySlug.PRODUCT_PLACE, 1)} | ${utils.getUnitCategoryName(
			unitData,
			CategorySlug.PRODUCT_PLACE,
			2
		)} | ${utils.getUnitCategoryName(unitData, CategorySlug.PRODUCT_PLACE, 3)} | ${utils.getUnitCategoryName(
			unitData,
			CategorySlug.PRODUCT_TYPE,
			2
		)}`;
	};

	const checkEnableDay = (dateStr) => {
		let selectedDay = new Date(dateStr).getDay();
		if (
			unitData?.enable_schedule?.booking_weekday &&
			!unitData?.enable_schedule?.booking_weekday.includes(selectedDay.toString())
		) {
			alert(`예약가능한 요일이 아닙니다.\n예약가능 요일: ${getEnabledWeekdayStr()}`);
			return false;
		}

		return true;
	};

	const checkEnableSchedule = (dateStr) => {
		let selectedDate = new Date(dateStr);
		let enableSchedule = true;
		// 시스템 제한 날짜를 확인한다.
		systemBlockScheduleList?.forEach((systemBlockSchedule) => {
			if (
				new Date(systemBlockSchedule.start_date) <= selectedDate &&
				new Date(systemBlockSchedule.end_date) >= selectedDate
			) {
				enableSchedule = false;
			}
		});

		// Unit 제한 날짜를 확인한다.
		unitData.disable_schedules?.forEach((unitDisableSchedule) => {
			if (
				new Date(unitDisableSchedule.start_date) <= selectedDate &&
				new Date(unitDisableSchedule.end_date) >= selectedDate
			) {
				enableSchedule = false;
			}
		});

		if (!enableSchedule) {
			alert(`예약가능한 날짜가 아닙니다.\n\n${getDisabledScheduleStr(selectedDate)}`);
			return false;
		}

		return true;
	};

	const getEnabledWeekdayStr = () => {
		return (
			unitData?.enable_schedule?.booking_weekday
				?.sort((a, b) => parseInt(a) - parseInt(b))
				?.map((weekday) => Day.toShortString(parseInt(weekday)))
				?.join(', ') ?? '-'
		);
	};

	const getDisabledScheduleStr = (selectedDate) => {
		let disableScheduleList = [];
		systemBlockScheduleList?.forEach((systemBlockSchedule) => {
			if (
				new Date(systemBlockSchedule.start_date) <= selectedDate &&
				new Date(systemBlockSchedule.end_date) >= selectedDate
			) {
				disableScheduleList.push(
					`${systemBlockSchedule.start_date}~${systemBlockSchedule.end_date} : ${systemBlockSchedule.name}`
				);
			}
		});
		// 시스템 설정에 걸리는 예약 기간이 없는 경우
		if (disableScheduleList.length === 0) {
			unitData.disable_schedules?.forEach((unitDisableSchedule) => {
				if (
					new Date(unitDisableSchedule.start_date) <= selectedDate &&
					new Date(unitDisableSchedule.end_date) >= selectedDate
				) {
					disableScheduleList.push(
						`${unitDisableSchedule.start_date}~${unitDisableSchedule.end_date} : ${unitDisableSchedule.name}`
					);
				}
			});
		}
		return disableScheduleList.sort().join('\n');
	};

	const getBookingScheduleDays = () => {
		if (!usageStartDate || !usageEndDate) {
			return '이용일자를 선택해 주세요';
		}

		const startDate = new Date(usageStartDate);
		const endDate = new Date(usageEndDate);

		return `${new Date(endDate - startDate).getDate()}일`;
	};

	const getBookingScheduleDate = () => {
		if (!usageStartDate || !usageEndDate) {
			return null;
		}

		return `(${usageStartDate} ~ ${usageEndDate})`;
	};

	const onTabChange = (idx) => {
		setTabIndex(idx);

		const element = document.getElementById(`section${idx}`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const onClickPrev = () => {
		navigate('/booking', {
			state: {
				unit_type: UnitType.PRODUCT,
			},
		});
	};

	const onClickNext = () => {
		if (!usageStartDate) {
			alert('선택한 대여일이 없습니다.');
			return;
		}
		if (!usageEndDate) {
			alert('선택한 반납일이 없습니다.');
			return;
		}

		const startDate = new Date(usageStartDate);
		const endDate = new Date(usageEndDate);
		if (new Date(endDate - startDate).getDate() > parseInt(unitData?.enable_schedule?.max_usage_time / 24 / 60)) {
			alert('최대 대여일수를 초과하였습니다.');
			return;
		}

		navigate('/booking/request', {
			state: {
				unit_id: id,
				filter: {
					usage_start_date: usageStartDate,
					usage_end_date: usageEndDate,
				},
			},
		});
	};

	const renderAdditionalImage = (media) => {
		return <AdditionalImage src={`${process.env.REACT_APP_API_URL}/storage/files/${media.image.filename}`} />;
	};

	const iframeStyle = {
		position: 'absolute',
		border: 0,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
	};

	const renderAdditionalLink = (media) => {
		return (
			<IframeWrapper>
				<AdditionalLink src={media.link} style={iframeStyle} />
			</IframeWrapper>
		);
	};

	if (!initialized) return null;

	return (
		<Content>
			<section>
				<Row>
					<UnitDataColumn>
						<ThumbnailContainer>
							{unitData?.thumbnail ? (
								<UnitThumbnail
									src={`${process.env.REACT_APP_API_URL}/storage/files/${unitData.thumbnail.filename}`}
								/>
							) : (
								<EmptyUnitThumbnail />
							)}
						</ThumbnailContainer>
						<TabContainer>
							<TabItemRow>
								<TabItem selected={tabIndex === 0} onClick={() => onTabChange(0)} topBorder>
									<span>기본정보</span>
									{tabIndex === 0 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
								<TabItem
									selected={tabIndex === 1}
									onClick={() => onTabChange(1)}
									topBorder
									rightBorder={!isDesktop}
								>
									<span>상세설명</span>
									{tabIndex === 1 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
							</TabItemRow>
							<TabItemRow>
								<TabItem selected={tabIndex === 2} onClick={() => onTabChange(2)} topBorder={isDesktop}>
									<span>이미지 및 영상</span>
									{tabIndex === 2 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
								<TabItem
									selected={tabIndex === 3}
									onClick={() => onTabChange(3)}
									topBorder={isDesktop}
									rightBorder
								>
									<span>대여/반납안내</span>
									{tabIndex === 3 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
							</TabItemRow>
						</TabContainer>
						<TabSection id="section0" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>기본정보</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>요약 소개</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.short_description ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>사용 용도</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.purpose ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>제공 장비</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.equipment ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>예약 정보</UnitDetailInfoTitle>
								<UnitDetailInfoRow>
									<UnitDetailInfo>{getEnabledWeekdayStr()}</UnitDetailInfo>
								</UnitDetailInfoRow>
							</UnitDetailInfoContainer>
						</TabSection>
						<TabSection id="section1" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>상세설명</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDescriptionViewer
									dangerouslySetInnerHTML={{ __html: unitData?.description ?? '-' }}
								/>
							</UnitDetailInfoContainer>
						</TabSection>
						<TabSection id="section2" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>이미지 및 영상</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitAdditionalMediaContainer>
									{unitData?.additional_medias.map((media, _) => {
										return (
											<div key={_}>
												{media.type === AdditionalMediaType.IMAGE
													? renderAdditionalImage(media)
													: renderAdditionalLink(media)}
											</div>
										);
									})}
								</UnitAdditionalMediaContainer>
							</UnitDetailInfoContainer>
						</TabSection>
						<TabSection id="section3" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>대여/반납안내</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDescriptionViewer
									dangerouslySetInnerHTML={{ __html: unitData?.location ?? '-' }}
								/>
							</UnitDetailInfoContainer>
						</TabSection>
					</UnitDataColumn>
					<UnitScheduleColumn>
						<UnitInfo>
							<UnitName>{unitData?.name}</UnitName>
							<UnitCategoryName>{getUnitCategoryFullName()}</UnitCategoryName>
							<UnitContactNumber>{`문의 : ${unitData?.contact_number ?? '-'}`}</UnitContactNumber>
						</UnitInfo>
						<BookingInfo>
							<BookingInfoContent>
								<BookingTitle>예약 신청</BookingTitle>
								<section>
									<BookingSubTitleContainer>
										<BookingSubTitle>이용일자</BookingSubTitle>
										<BookingEnableDayContainer>
											<CheckIcon src={CheckIconImage} />
											<BookingEnableDayTitle>{`예약 가능 요일 : `}</BookingEnableDayTitle>
											<BookingEnableDays>{getEnabledWeekdayStr()}</BookingEnableDays>
										</BookingEnableDayContainer>
									</BookingSubTitleContainer>
									<SearchDateContainer>
										<SearchDateInput
											type="date"
											value={usageStartDate ?? ''}
											min={
												utils.checkProductAdminRole(user, Number(id))
													? null
													: utils.convertDateToStr(new Date())
											}
											max={
												utils.checkProductAdminRole(user, Number(id))
													? null
													: unitData?.enable_schedule?.usage_end_date
											}
											onChange={(e) => {
												// 예약 가능한 요일인지 판단한다.
												if (!checkEnableDay(e.currentTarget.value)) {
													return;
												}
												// 제외 기간에 포함되는지 확인한다.
												if (!checkEnableSchedule(e.currentTarget.value)) {
													return;
												}
												if (usageEndDate && usageEndDate < e.currentTarget.value) {
													alert('대여일은 반납일보다 이후일 수 없습니다.');
													return;
												}
												setUsageStartDate(e.currentTarget.value);
											}}
										/>
										<SearchDateInputSeparator>~</SearchDateInputSeparator>
										<SearchDateInput
											type="date"
											value={usageEndDate ?? ''}
											min={
												utils.checkProductAdminRole(user, Number(id))
													? null
													: utils.convertDateToStr(new Date())
											}
											max={
												utils.checkProductAdminRole(user, Number(id))
													? null
													: unitData?.enable_schedule?.usage_end_date
											}
											onChange={(e) => {
												// 예약 가능한 요일인지 판단한다.
												if (!checkEnableDay(e.currentTarget.value)) {
													return;
												}
												// 제외 기간에 포함되는지 확인한다.
												if (!checkEnableSchedule(e.currentTarget.value)) {
													return;
												}
												if (usageStartDate && usageStartDate > e.currentTarget.value) {
													alert('반납일은 대여일보다 이전일 수 없습니다.');
													return;
												}
												setUsageEndDate(e.currentTarget.value);
											}}
										/>
									</SearchDateContainer>
									<BookingEnableDayInformation>{`* 이용시작/종료일은 예약 가능 요일 중에서 지정할 수 있습니다.`}</BookingEnableDayInformation>
								</section>
								<section>
									<BookingBlockTimeContainer>
										<BookingSubTitleContainer inline className="mt-0">
											<BookingSubTitle>이용기간</BookingSubTitle>
										</BookingSubTitleContainer>
										<BookingSubTitleDescription>{`최대 대여일수 : ${convertMinuteToDayStr(
											unitData?.enable_schedule?.max_usage_time ?? 0
										)}`}</BookingSubTitleDescription>
									</BookingBlockTimeContainer>
									<BookingBlockTime>
										<BookingBlockTimeText>{getBookingScheduleDays()}</BookingBlockTimeText>
										{getBookingScheduleDate() !== null && (
											<BookingBlockTimeInfoText>
												{getBookingScheduleDate()}
											</BookingBlockTimeInfoText>
										)}
									</BookingBlockTime>
								</section>
							</BookingInfoContent>
							<ButtonContainer>
								<StepButton onClick={onClickPrev}>
									<StepButtonPrev>{'<'}</StepButtonPrev>
									<span>이전단계</span>
								</StepButton>
								<StepButton onClick={onClickNext}>
									<StepButtonNext>{'>'}</StepButtonNext>
									<span>다음단계</span>
								</StepButton>
							</ButtonContainer>
						</BookingInfo>
					</UnitScheduleColumn>
				</Row>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 59px;
	margin-bottom: 106px;

	@media only screen and (max-width: 767.98px) {
		padding: 10px;
		margin: 0;
	}
`;

const Row = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
	}
`;

const UnitDataColumn = styled.div`
	width: 800px;
	background-color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 15px 0;
		margin-top: 10px;
	}
`;

const ThumbnailContainer = styled.div`
	padding: 45px 50px;
	box-sizing: border-box;

	@media only screen and (max-width: 767.98px) {
		padding: 0 10px;
	}
`;

const UnitThumbnail = styled.img`
	width: 100%;
	height: 440px;
	object-fit: contain;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const EmptyUnitThumbnail = styled.div`
	width: 100%;
	height: 440px;
	background-color: #dddddd;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const TabContainer = styled.div`
	display: flex;
	position: sticky;
	top: 90px;
	z-index: 50;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		width: 100%;
		margin-top: 10px;
		top: 60px;
	}
`;

const TabItemRow = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const TabItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 200px;
	height: 60px;
	padding: 20px;
	box-sizing: border-box;
	cursor: pointer;

	span {
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		color: #000000;
	}

	border-left: 1px solid #a0a8b8;
	border-bottom: 1px solid #123277;
	background-color: #fdfeff;

	${(props) =>
		props.topBorder &&
		css`
			border-top: 1px solid #a0a8b8;
		`}

	${(props) =>
		props.rightBorder &&
		css`
			border-right: 1px solid #a0a8b8;
		`}
		
	${(props) =>
		props.selected &&
		css`
			span {
				color: #ffffff;
			}
			background-color: #203970;
		`}

	
	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 45px;
		padding: 10px;
		span {
			font-size: 16px;
			line-height: 19px;
		}
	}
`;

const TabArrowDownActiveIcon = styled.i`
	width: 24px;
	height: 24px;
	background-image: url(${TabArrowDownActiveIconImage});
`;

const TabArrowDownNormalIcon = styled.i`
	width: 24px;
	height: 24px;
	background-image: url(${TabArrowDownNormalIconImage});
`;

const TabSection = styled.section`
	scroll-margin-top: 150px;
	padding: 30px 30px 25px 30px;
	box-sizing: border-box;

	@media only screen and (max-width: 767.98px) {
		padding: 10px;
		scroll-margin-top: 150px;
	}
`;

const UnitDetailInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	& + & {
		margin-top: 20px;
	}
`;

const UnitDetailInfoTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 17px;
	color: #333333;

	@media only screen and (max-width: 767.98px) {
		font-size: 15px;
		line-height: 18px;
	}
`;

const UnitDetailInfoSectionTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;
`;

const UnitDetailInfoRow = styled.div`
	display: flex;
`;

const UnitDetailInfo = styled.div`
	margin-top: 12px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	white-space: pre-line;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const UnitAdditionalMediaContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const UnitDescriptionViewer = styled.div`
	width: 100%;
	font-size: 16px;
	margin-top: 10px;
	overflow-x: auto;
`;

const AdditionalImage = styled.img`
	width: 100%;
	margin-top: 12px;
`;

const AdditionalLink = styled.iframe``;

const IframeWrapper = styled.div`
	margin-top: 12px;
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
`;

const SectionSeparator = styled.div`
	position: relative;
	margin-top: 16px;
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #cccccc;
`;

const SectionSeparatorHead = styled.div`
	position: absolute;
	top: -1px;
	left: 0;
	width: 18px;
	height: 2px;
	background-color: #123277;
`;

const UnitScheduleColumn = styled.div`
	width: 412px;
	margin-left: 32px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
	}
`;

const UnitInfo = styled.div`
	width: 100%;
	background-color: #ffffff;
	border: 5px solid #cad1e3;
	padding: 30px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 125px;
		padding: 15px 16px 16px 16px;
	}
`;

const UnitName = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding-bottom: 19px;
	border-bottom: 1px solid #dddddd;
	word-break: break-all;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		display: block;
		height: 34px;
		padding-bottom: 10px;
		font-size: 20px;
		line-height: 24px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		word-break: break-all;
	}
`;

const UnitCategoryName = styled.div`
	margin-top: 18px;
	height: 20px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #22499d;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		font-size: 14px;
		line-height: 17px;
	}
`;

const UnitContactNumber = styled.div`
	margin-top: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		margin-top: 5px;
		font-size: 14px;
		line-height: 17px;
	}
`;

const BookingInfo = styled.div`
	position: sticky;
	top: 110px;
	width: 100%;
	margin-top: 20px;
	background-color: #ffffff;
	border: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		position: relative;
		top: 0;
		margin-top: 10px;
	}
`;

const BookingInfoContent = styled.div`
	padding: 25px 30px 30px 30px;

	@media only screen and (max-width: 767.98px) {
		padding: 15px 10px;
	}
`;

const BookingTitle = styled.div`
	padding-bottom: 20px;
	border-bottom: 1px solid #dddddd;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	line-height: 31px;
	color: #333333;

	@media only screen and (max-width: 767.98px) {
		padding-bottom: 12px;
		font-size: 20px;
		line-height: 24px;
	}
`;

const BookingSubTitleContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	margin-bottom: 11px;
	display: flex;
	align-items: center;

	${(props) =>
		props.inline &&
		css`
			width: auto;
			margin-bottom: 0;
		`}

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
	}
`;

const BookingSubTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #023169;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const BookingEnableDayContainer = styled.div`
	margin-left: 9px;
	display: flex;
	align-items: center;
`;

const CheckIcon = styled.img`
	width: 15px;
	height: 15px;
`;

const BookingEnableDayTitle = styled.span`
	margin-left: 3px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const BookingEnableDays = styled.span`
	margin-left: 3px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #e76f00;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const BookingEnableDayInformation = styled.div`
	margin-top: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #22499d;
`;

const SearchDateContainer = styled.div``;

const SearchDateInput = styled.input`
	width: 157px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;
	border-radius: 4px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		width: 40%;
	}
`;

const SearchDateInputSeparator = styled.span`
	margin: 0 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
`;

const BookingBlockTimeContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 30px;
	align-items: center;
	justify-content: space-between;
`;

const BookingSubTitleDescription = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-align: right;
	color: #666666;
`;

const BookingBlockTime = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;
	padding: 15px 0;
	margin-top: 18px;
	background-color: #f0f4fd;
	border-radius: 5px;
`;

const BookingBlockTimeText = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const BookingBlockTimeInfoText = styled.div`
	margin-top: 7px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #4a4a4a;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const StepButton = styled.div`
	position: relative;
	width: 50%;
	height: 64px;
	border: 1px solid #8f8f8f;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #000000;

	& + & {
		border-left: 0px;
	}

	&:hover {
		background-color: #22499d;
		color: #ffffff;
	}

	@media only screen and (max-width: 767.98px) {
		height: 46px;
		font-size: 16px;
		line-height: 19px;
	}
`;

const StepButtonPrev = styled.div`
	position: absolute;
	top: 20px;
	left: 20px;

	@media only screen and (max-width: 767.98px) {
		top: 12px;
	}
`;

const StepButtonNext = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;

	@media only screen and (max-width: 767.98px) {
		top: 12px;
	}
`;

export default BookingProductSchedule;
