import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';
import { UserContext } from '../contexts/UserContext';
import Day from '../constants/Day';
import CategorySlug from '../constants/CategorySlug';
import AdditionalMediaType from '../constants/AdditionalMediaType';
import UnitType from '../constants/UnitType';
import TimeSelect from '../components/TimeSelect';

import TabArrowDownActiveIconImage from '../assets/images/screens/Booking/tab_arrow_down_active.svg';
import TabArrowDownNormalIconImage from '../assets/images/screens/Booking/tab_arrow_down_normal.svg';
import ReloadBlueIconImage from '../assets/images/screens/Booking/reload_blue.svg';
import ReloadWhiteIconImage from '../assets/images/screens/Booking/reload_white.svg';

import * as api from '../apis';
import * as utils from '../utils';

const BookingSchedule = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { isDesktop } = useSizeDetector();
	const { user } = useContext(UserContext);

	const [tabIndex, setTabIndex] = useState(0);
	const [unitData, setUnitData] = useState(null);
	const [usageDate, setUsageDate] = useState(null);
	const [startTime, setStartTime] = useState(null);
	const [blockTime, setBlockTime] = useState(0);
	const [scheduleList, setScheduleList] = useState([]);

	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		setUsageDate(location.state?.filter?.usage_date ?? null);

		api.getUnit(id)
			.then((res) => {
				if (res && res.data) {
					setUnitData(res.data);
					setBlockTime(res.data?.enable_schedule?.block_time ?? 0);
					setInitialized(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!initialized) return;

		// 데스크톱 메뉴 스크롤 감지
		const option = {
			root: null, //viewport
			rootMargin: '0px',
			threshold: 0.45, // 45%가 viewport에 들어와 있어야 callback 실행
		};
		const callback = (entries, observer) => {
			entries.forEach((entry) => {
				let targetElemId = null;
				if (entry.isIntersecting) {
					targetElemId = entry.target.id;
				}
				if (targetElemId) {
					setTabIndex(parseInt(targetElemId.replace('section', '')));
				}
			});
		};
		const observer = new IntersectionObserver(callback, option);
		const sections = document.getElementsByClassName('tab-section');
		for (let section of sections) {
			observer.observe(section);
		}
	}, [initialized]);

	useEffect(() => {
		if (id && usageDate) {
			api.getUnitSchedule(id, usageDate)
				.then((res) => {
					if (res && res.data) {
						setScheduleList(res.data);
						checkBookingSchedule(res.data);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [usageDate]);

	useEffect(() => {
		checkBookingSchedule(scheduleList);
	}, [startTime, blockTime]);

	const checkBookingSchedule = (currentScheduleList) => {
		if (!startTime || currentScheduleList.length === 0) return;

		const bookingEndTime = calcBookingEndTime(Date.now(), startTime, blockTime);
		setScheduleList(
			currentScheduleList.map((schedule) => {
				return {
					...schedule,
					booked: schedule.from >= startTime && schedule.to <= bookingEndTime,
				};
			})
		);
	};

	const calcBookingEndTime = (usageDate, startTime, block) => {
		const splited = startTime.split(':');

		let date = new Date(usageDate);
		date.setHours(splited[0]);
		date.setMinutes(splited[1]);

		let endTime = new Date(date.getTime() + block * 60000);
		endTime.toLocaleTimeString('en-US', { hour12: false });

		// 다음 날짜까지 예약을 하려는 경우 block한다
		if (date.getDay() !== endTime.getDay()) {
			alert('오늘 날짜를 초과해서 예약하실 수 없습니다.');
			return;
		}

		let endHour = endTime.getHours();
		let endMinutes = endTime.getMinutes();

		return `${String(endHour).padStart(2, '0')}:${String(endMinutes).padStart(2, '0')}`;
	};

	const convertMinuteToHourMinuteStr = (minute) => {
		let hour = parseInt(minute / 60);
		minute -= hour * 60;

		if (hour === 0) {
			return `${minute}분`;
		}
		return `${hour}시간 ${minute}분`;
	};

	const getUnitCategoryFullName = () => {
		return `${utils.getUnitCategoryName(unitData, CategorySlug.PLACE, 1)} | ${utils.getUnitCategoryName(
			unitData,
			CategorySlug.PLACE,
			2
		)} | ${utils.getUnitCategoryName(unitData, CategorySlug.PLACE, 3)} | ${utils.getUnitCategoryName(
			unitData,
			CategorySlug.TYPE,
			2
		)}`;
	};

	const getEnabledWeekdayStr = () => {
		return (
			unitData?.enable_schedule?.booking_weekday
				?.map((weekday) => Day.toShortString(parseInt(weekday)))
				?.join(', ') ?? '-'
		);
	};

	const getBookingScheduleTime = () => {
		if (!startTime) {
			return '(시작시간을 선택해 주세요)';
		}

		const bookingEndTime = calcBookingEndTime(Date.now(), startTime, blockTime);
		return `(${startTime}~${bookingEndTime})`;
	};

	/**
	 * 선택한 예약 날짜가 예약이 가능한지 확인한다.
	 */
	const validateBookingDate = (selectedDate) => {
		// 예약 가능한 요일인지 판단한다.
		const selectedDay = selectedDate.getDay();
		if (
			unitData?.enable_schedule?.booking_weekday &&
			!unitData?.enable_schedule?.booking_weekday.includes(selectedDay.toString())
		) {
			alert(`예약가능한 요일이 아닙니다.\n예약가능 요일: ${getEnabledWeekdayStr()}`);
			return false;
		}

		// 예약 신청이 가능한 날짜인지 판단한다. 신청 가능 시간이 0으로 되어 있는 경우 체크하지 않는다. (당일 예약 허용)
		const enableRequestDayBefore = unitData?.restrict_day?.enable_request_day_before ?? 0;
		if (enableRequestDayBefore !== 0 && !utils.checkAvailableBookingDate(selectedDate, enableRequestDayBefore)) {
			alert(
				`신청 가능 기간이 아닙니다.\n- 신청 가능 기간 : 예약시작일 기준 ${enableRequestDayBefore}일 전까지 허용`
			);
			return false;
		}

		return true;
	};

	const onTabChange = (idx) => {
		setTabIndex(idx);

		const element = document.getElementById(`section${idx}`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const onClickPrev = () => {
		navigate('/booking');
	};

	const onClickNext = () => {
		if (!usageDate) {
			alert('선택한 이용일자가 없습니다.');
			return;
		}
		if (!startTime) {
			alert('선택한 시작 시간이 없습니다.');
			return;
		}
		if (!blockTime) {
			alert('최소 이용시간을 설정하지 않았습니다.');
			return;
		}

		const bookingEndTime = calcBookingEndTime(usageDate, startTime, blockTime);
		if (!bookingEndTime) {
			return;
		}

		// 시작 시간이 scheduleList의 첫번째 시간보다 이후여야 한다.
		if (scheduleList.length !== 0 && startTime < scheduleList[0].from) {
			alert('예약 가능 시간이 아닙니다.');
			return;
		}
		// 종료 시간이 scheduleList의 마지막 시간보다 이전이어야 한다.
		if (scheduleList.length !== 0 && bookingEndTime > scheduleList[scheduleList.length - 1].to) {
			alert('예약 가능 시간이 아닙니다.');
			return;
		}
		let scheduleEnable = true;
		for (let idx in scheduleList) {
			let schedule = scheduleList[idx];
			if (startTime < schedule.to && bookingEndTime > schedule.from && schedule.enabled === false) {
				scheduleEnable = false;
				break;
			}
		}
		if (!scheduleEnable) {
			alert('예약 가능 시간이 아닙니다.');
			return;
		}

		navigate('/booking/request', {
			state: {
				unit_id: id,
				filter: {
					usage_date: usageDate,
					booking_start_time: startTime,
					booking_end_time: bookingEndTime,
				},
			},
		});
	};

	const renderAdditionalImage = (media) => {
		return <AdditionalImage src={`${process.env.REACT_APP_API_URL}/storage/files/${media.image.filename}`} />;
	};

	const iframeStyle = {
		position: 'absolute',
		border: 0,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
	};

	const renderAdditionalLink = (media) => {
		return (
			<IframeWrapper>
				<AdditionalLink src={media.link} style={iframeStyle} />
			</IframeWrapper>
		);
	};

	if (!initialized) return null;

	return (
		<Content>
			<section>
				<Row>
					<UnitDataColumn>
						<ThumbnailContainer>
							{unitData?.thumbnail ? (
								<UnitThumbnail
									src={`${process.env.REACT_APP_API_URL}/storage/files/${unitData.thumbnail.filename}`}
								/>
							) : (
								<EmptyUnitThumbnail />
							)}
						</ThumbnailContainer>
						<TabContainer>
							<TabItemRow>
								<TabItem selected={tabIndex === 0} onClick={() => onTabChange(0)} topBorder>
									<span>기본정보</span>
									{tabIndex === 0 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
								<TabItem
									selected={tabIndex === 1}
									onClick={() => onTabChange(1)}
									topBorder
									rightBorder={!isDesktop}
								>
									<span>상세설명</span>
									{tabIndex === 1 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
							</TabItemRow>
							<TabItemRow>
								<TabItem selected={tabIndex === 2} onClick={() => onTabChange(2)} topBorder={isDesktop}>
									<span>이미지 및 영상</span>
									{tabIndex === 2 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
								<TabItem
									selected={tabIndex === 3}
									onClick={() => onTabChange(3)}
									topBorder={isDesktop}
									rightBorder
								>
									<span>위치</span>
									{tabIndex === 3 ? <TabArrowDownActiveIcon /> : <TabArrowDownNormalIcon />}
								</TabItem>
							</TabItemRow>
						</TabContainer>
						<TabSection id="section0" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>기본정보</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>시설소개</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.short_description ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>사용 용도</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.purpose ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>제작 장비</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.equipment ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>예약 정보</UnitDetailInfoTitle>
								<UnitDetailInfoRow>
									<UnitDetailInfo>{getEnabledWeekdayStr()}</UnitDetailInfo>
									<UnitDetailInfoSeparator>|</UnitDetailInfoSeparator>
									<UnitDetailInfo>
										{unitData?.enable_schedule?.booking_start_time ?? '-'} ~{' '}
										{unitData?.enable_schedule?.booking_end_time ?? '-'}
									</UnitDetailInfo>
								</UnitDetailInfoRow>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>호실 정보</UnitDetailInfoTitle>
								<UnitDetailInfo>
									{unitData?.room_items?.map((roomItem) => roomItem.name).join(', ')}
								</UnitDetailInfo>
							</UnitDetailInfoContainer>
						</TabSection>
						<TabSection id="section1" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>상세설명</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDescriptionViewer
									dangerouslySetInnerHTML={{ __html: unitData?.description ?? '-' }}
								/>
							</UnitDetailInfoContainer>
						</TabSection>
						<TabSection id="section2" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>이미지 및 영상</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitAdditionalMediaContainer>
									{unitData?.additional_medias.map((media, _) => {
										return (
											<div key={_}>
												{media.type === AdditionalMediaType.IMAGE
													? renderAdditionalImage(media)
													: renderAdditionalLink(media)}
											</div>
										);
									})}
								</UnitAdditionalMediaContainer>
							</UnitDetailInfoContainer>
						</TabSection>
						<TabSection id="section3" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>위치</UnitDetailInfoSectionTitle>
								<SectionSeparator>
									<SectionSeparatorHead />
								</SectionSeparator>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDescriptionViewer
									dangerouslySetInnerHTML={{ __html: unitData?.location ?? '-' }}
								/>
							</UnitDetailInfoContainer>
						</TabSection>
					</UnitDataColumn>
					<UnitScheduleColumn>
						<UnitInfo>
							<UnitName>{unitData?.name}</UnitName>
							<UnitCategoryName>{getUnitCategoryFullName()}</UnitCategoryName>
							<UnitContactNumber>{`문의 : ${unitData?.contact_number ?? '-'}`}</UnitContactNumber>
						</UnitInfo>
						<BookingInfo>
							<BookingInfoContent>
								<BookingTitle>예약 신청</BookingTitle>
								<section>
									<BookingSubTitleContainer>
										<BookingSubTitle>이용일자</BookingSubTitle>
									</BookingSubTitleContainer>
									<SearchDateInput
										type="date"
										value={usageDate ?? ''}
										min={
											utils.checkRoomAdminRole(user, Number(id))
												? null
												: utils.convertDateToStr(new Date())
										}
										max={
											utils.checkRoomAdminRole(user, Number(id))
												? null
												: unitData?.enable_schedule?.usage_end_date
										}
										onChange={(e) => {
											// 운영자가 아닌 경우 예약 가능날짜를 확인한다.
											if (
												!utils.checkRoomAdminRole(user, Number(id)) &&
												!validateBookingDate(new Date(e.currentTarget.value))
											) {
												return;
											}
											setUsageDate(e.currentTarget.value);
										}}
									/>
								</section>
								<section>
									<BookingAvailableContainer>
										<BookingSubTitleContainer inline className="mt-0">
											<BookingSubTitle>예약가능 시간</BookingSubTitle>
										</BookingSubTitleContainer>
										<BookingBlockContainer>
											<BookingBlockSqure />
											<BookingBlockTitle>예약불가</BookingBlockTitle>
										</BookingBlockContainer>
									</BookingAvailableContainer>
									<BookingAvailableTable>
										{scheduleList.map((schedule, idx) => {
											let text = schedule.from_time_str ?? '';
											let smallText = text.length === 1;
											let lastBox = idx === scheduleList.length - 1;
											if (lastBox) text = schedule.to_time_str ?? '';
											return (
												<ScheduleBox
													key={idx}
													enabled={schedule?.enabled}
													booked={schedule?.booked}
													start={schedule.from_time_str ? 'true' : 'false'}
												>
													<ScheduleBoxText small={smallText} last={lastBox}>
														{text}
													</ScheduleBoxText>
												</ScheduleBox>
											);
										})}
									</BookingAvailableTable>
								</section>
								<section className="mt-40px">
									<BookingSubTitleContainer>
										<BookingSubTitle>시작시간</BookingSubTitle>
									</BookingSubTitleContainer>
									<TimeSelect
										time={startTime}
										minHour={
											parseInt(unitData?.enable_schedule?.booking_start_time.substring(0, 2)) ?? 0
										}
										maxHour={
											parseInt(unitData?.enable_schedule?.booking_end_time.substring(0, 2)) ?? 0
										}
										onChange={(value) => setStartTime(value)}
									></TimeSelect>
								</section>
								<section>
									<BookingBlockTimeContainer>
										<BookingSubTitleContainer inline className="mt-0">
											<BookingSubTitle>이용시간</BookingSubTitle>
										</BookingSubTitleContainer>
										<BookingSubTitleDescription>{`최소 이용시간: ${
											unitData?.enable_schedule?.block_time ?? 0
										}분`}</BookingSubTitleDescription>
									</BookingBlockTimeContainer>
									<BookingBlockTime>
										<BookingBlockTimeText>
											{convertMinuteToHourMinuteStr(blockTime ?? 0)}
										</BookingBlockTimeText>
										<BookingBlockTimeInfoText>{getBookingScheduleTime()}</BookingBlockTimeInfoText>
									</BookingBlockTime>
									<BlockTimeUnitContainer>
										<BlockTimeUnit
											left
											onClick={() => {
												if (
													blockTime + unitData?.enable_schedule?.block_time >
													unitData?.enable_schedule?.max_usage_time
												) {
													return;
												}
												setBlockTime(blockTime + unitData?.enable_schedule?.block_time ?? 0);
											}}
										>
											<span>{`+${unitData?.enable_schedule?.block_time ?? '0'}분`}</span>
										</BlockTimeUnit>
										<BlockTimeUnit
											right
											onClick={() => {
												if (blockTime > unitData?.enable_schedule?.block_time) {
													setBlockTime(
														blockTime - unitData?.enable_schedule?.block_time ?? 0
													);
												}
											}}
										>
											<span>{`-${unitData?.enable_schedule?.block_time ?? '0'}분`}</span>
										</BlockTimeUnit>
										<BlockTimeUnit
											reset
											onClick={() => {
												setBlockTime(unitData?.enable_schedule?.block_time);
											}}
										>
											<ReloadBlueIcon />
											<ReloadWhiteIcon />
											<span>재설정</span>
										</BlockTimeUnit>
									</BlockTimeUnitContainer>
								</section>
							</BookingInfoContent>

							<ButtonContainer>
								<StepButton onClick={onClickPrev}>
									<StepButtonPrev>{'<'}</StepButtonPrev>
									<span>이전단계</span>
								</StepButton>
								<StepButton onClick={onClickNext}>
									<StepButtonNext>{'>'}</StepButtonNext>
									<span>다음단계</span>
								</StepButton>
							</ButtonContainer>
						</BookingInfo>
					</UnitScheduleColumn>
				</Row>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 59px;
	margin-bottom: 106px;

	@media only screen and (max-width: 767.98px) {
		padding: 10px;
		margin: 0;
	}
`;

const Row = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
	}
`;

const UnitDataColumn = styled.div`
	width: 800px;
	background-color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 15px 0;
		margin-top: 10px;
	}
`;

const ThumbnailContainer = styled.div`
	padding: 45px 50px;
	box-sizing: border-box;

	@media only screen and (max-width: 767.98px) {
		padding: 0 10px;
	}
`;

const UnitThumbnail = styled.img`
	width: 100%;
	height: 440px;
	object-fit: contain;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const EmptyUnitThumbnail = styled.div`
	width: 100%;
	height: 440px;
	background-color: #dddddd;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const TabContainer = styled.div`
	display: flex;
	position: sticky;
	top: 90px;
	z-index: 50;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		width: 100%;
		margin-top: 10px;
		top: 60px;
	}
`;

const TabItemRow = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const TabItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 200px;
	height: 60px;
	padding: 20px;
	box-sizing: border-box;
	cursor: pointer;

	span {
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		color: #000000;
	}

	border-left: 1px solid #a0a8b8;
	border-bottom: 1px solid #123277;
	background-color: #fdfeff;

	${(props) =>
		props.topBorder &&
		css`
			border-top: 1px solid #a0a8b8;
		`}

	${(props) =>
		props.rightBorder &&
		css`
			border-right: 1px solid #a0a8b8;
		`}
		
	${(props) =>
		props.selected &&
		css`
			span {
				color: #ffffff;
			}
			background-color: #203970;
		`}

	
	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 45px;
		padding: 10px;
		span {
			font-size: 16px;
			line-height: 19px;
		}
	}
`;

const TabArrowDownActiveIcon = styled.i`
	width: 24px;
	height: 24px;
	background-image: url(${TabArrowDownActiveIconImage});
`;

const TabArrowDownNormalIcon = styled.i`
	width: 24px;
	height: 24px;
	background-image: url(${TabArrowDownNormalIconImage});
`;

const TabSection = styled.section`
	scroll-margin-top: 150px;
	padding: 30px 30px 25px 30px;
	box-sizing: border-box;

	@media only screen and (max-width: 767.98px) {
		padding: 10px;
		scroll-margin-top: 150px;
	}
`;

const UnitDetailInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	& + & {
		margin-top: 20px;
	}
`;

const UnitDetailInfoTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 17px;
	color: #333333;

	@media only screen and (max-width: 767.98px) {
		font-size: 15px;
		line-height: 18px;
	}
`;

const UnitDetailInfoSectionTitle = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const UnitDetailInfoRow = styled.div`
	display: flex;
`;

const UnitDetailInfo = styled.div`
	margin-top: 12px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	white-space: pre-line;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const UnitDetailInfoSeparator = styled(UnitDetailInfo)`
	margin-left: 10px;
	margin-right: 10px;
`;

const UnitAdditionalMediaContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const UnitDescriptionViewer = styled.div`
	width: 100%;
	font-size: 16px;
	margin-top: 10px;
	overflow-x: auto;
`;

const AdditionalImage = styled.img`
	width: 100%;
	margin-top: 12px;
`;

const AdditionalLink = styled.iframe``;

const IframeWrapper = styled.div`
	margin-top: 12px;
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
`;

const SectionSeparator = styled.div`
	position: relative;
	margin-top: 16px;
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #cccccc;
`;

const SectionSeparatorHead = styled.div`
	position: absolute;
	top: -1px;
	left: 0;
	width: 18px;
	height: 2px;
	background-color: #123277;
`;

const UnitScheduleColumn = styled.div`
	width: 412px;
	margin-left: 32px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
	}
`;

const UnitInfo = styled.div`
	width: 100%;
	background-color: #ffffff;
	border: 5px solid #cad1e3;
	padding: 30px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 125px;
		padding: 15px 16px 16px 16px;
	}
`;

const UnitName = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding-bottom: 19px;
	border-bottom: 1px solid #dddddd;
	word-break: break-all;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
	color: #000000;

	@media only screen and (max-width: 767.98px) {
		display: block;
		height: 34px;
		padding-bottom: 10px;
		font-size: 20px;
		line-height: 24px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		word-break: break-all;
	}
`;

const UnitCategoryName = styled.div`
	margin-top: 18px;
	height: 20px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #22499d;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		font-size: 14px;
		line-height: 17px;
	}
`;

const UnitContactNumber = styled.div`
	margin-top: 10px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		margin-top: 5px;
		font-size: 14px;
		line-height: 17px;
	}
`;

const BookingInfo = styled.div`
	position: sticky;
	top: 110px;
	width: 100%;
	margin-top: 20px;
	background-color: #ffffff;
	border: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		position: relative;
		top: 0;
		margin-top: 10px;
	}
`;

const BookingInfoContent = styled.div`
	padding: 25px 30px 30px 30px;

	@media only screen and (max-width: 767.98px) {
		padding: 15px 10px;
	}
`;

const BookingTitle = styled.div`
	padding-bottom: 20px;
	border-bottom: 1px solid #dddddd;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	line-height: 31px;
	color: #333333;

	@media only screen and (max-width: 767.98px) {
		padding-bottom: 12px;
		font-size: 20px;
		line-height: 24px;
	}
`;

const BookingSubTitleContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	margin-bottom: 11px;
	display: flex;
	align-items: center;

	${(props) =>
		props.inline &&
		css`
			width: auto;
			margin-bottom: 0;
		`}

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
	}
`;

const BookingSubTitle = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #023169;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const SearchDateInput = styled.input`
	width: 200px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;
	border-radius: 4px;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const BookingAvailableContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
	margin-bottom: 14px;
`;

const BookingBlockContainer = styled.div`
	display: flex;
	align-items: center;
`;

const BookingBlockSqure = styled.div`
	width: 15px;
	height: 15px;
	background-color: #cccccc;
`;

const BookingBlockTitle = styled.span`
	margin-left: 7px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

const BookingAvailableTable = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ScheduleBox = styled.div`
	width: 20px;
	height: 15px;
	border: 1px solid #6d9ed8;
	border-right: none;

	&:nth-last-child(1) {
		border-right: 1px solid #6d9ed8;
	}
	&:nth-child(2n + 1) {
		border-style: solid dashed solid solid;
		border-right: 1px dashed #dddddd;
	}
	&:nth-child(2n) {
		border-left: none;
	}
	${(props) =>
		props.booked &&
		css`
			background-color: #e4f0fa;
			border: 1px solid #6d9ed8;
			border-right: none;
		`}
	${(props) =>
		!props.enabled &&
		css`
			background-color: #cccccc;
			border: 1px solid #6d9ed8;
			border-right: none;
		`}
`;

const ScheduleBoxText = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.28px;
	line-height: 100%;

	position: relative;
	top: 17px;
	left: -7px;

	${(props) =>
		props.last &&
		css`
			left: 10px;
		`}
	${(props) =>
		props.small &&
		css`
			left: -2px;
		`}
`;

const BookingBlockTimeContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 30px;
	align-items: center;
	justify-content: space-between;
`;

const BookingSubTitleDescription = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-align: right;
	color: #666666;
`;

const BookingBlockTime = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;
	padding: 15px 0;
	margin-top: 18px;
	background-color: #f0f4fd;
	border-radius: 5px;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #22499d;
`;

const BookingBlockTimeText = styled.div`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #22499d;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 19px;
	}
`;

const BookingBlockTimeInfoText = styled.div`
	margin-top: 7px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #4a4a4a;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const BlockTimeUnitContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 10px;
`;

const ReloadBlueIcon = styled.i`
	display: block;
	width: 16px;
	height: 16px;
	margin-right: 9px;
	background-image: url(${ReloadBlueIconImage});
`;

const ReloadWhiteIcon = styled.i`
	display: none;
	width: 16px;
	height: 16px;
	margin-right: 9px;
	background-image: url(${ReloadWhiteIconImage});
`;

const BlockTimeUnit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	width: 110px;
	height: 50px;
	border: 1px solid #333333;
	cursor: pointer;

	span {
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		color: #4a4a4a;
	}

	&:hover {
		background-color: #e9e9e9;
	}
	${(props) =>
		props.left &&
		css`
			border-radius: 5px 0px 0px 5px;
			border-right: 0px;
		`}

	${(props) =>
		props.right &&
		css`
			border-radius: 0px 5px 5px 0px;
		`}

	${(props) =>
		props.reset &&
		css`
			margin-left: 10px;
			border: 1px solid #22499d;
			border-radius: 5px;
			span {
				color: #22499d;
			}

			&:hover {
				background-color: #0c438d;
				span {
					color: #ffffff;
				}

				& > ${ReloadBlueIcon} {
					display: none;
				}
				& > ${ReloadWhiteIcon} {
					display: block;
				}
			}
		`}

	@media only screen and (max-width: 767.98px) {
		span {
			font-size: 14px;
			line-height: 17px;
		}
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const StepButton = styled.div`
	position: relative;
	width: 50%;
	height: 64px;
	border: 1px solid #8f8f8f;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #000000;

	& + & {
		border-left: 0px;
	}

	&:hover {
		background-color: #22499d;
		color: #ffffff;
	}

	@media only screen and (max-width: 767.98px) {
		height: 46px;
		font-size: 16px;
		line-height: 19px;
	}
`;

const StepButtonPrev = styled.div`
	position: absolute;
	top: 20px;
	left: 20px;

	@media only screen and (max-width: 767.98px) {
		top: 12px;
	}
`;

const StepButtonNext = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;

	@media only screen and (max-width: 767.98px) {
		top: 12px;
	}
`;

export default BookingSchedule;
